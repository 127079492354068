/* Wishlist Overrides */
#divWishList .wishlist-summary {
    top: 70px;

    .wishlist-summary-header {
        .wishlist-summary-tick svg .tick-circle {
            fill: var(--site-accent);
        }

        .wishlist-summary-tick svg .tick-tick {
            fill: var(--site-white);
        }
    }

    .items-container {
        .product-line-card__description-subtotal-amount {
            font-weight: var(--font-weight-bold);
        }
    }

    .wishlist-summary-footer {
        .ctaBtn {
            font-weight: var(--font-weight-bold);
        }
    }
}

.wishlist-page {
    &__empty {
        .global-icon-wishlist svg {
            width: 24px;
        }
    }

    .wishlist-page__header h1 {
        svg .tick-inner {
            fill: var(--site-white);
        }

        span .itemCount {
            text-transform: none;
        }
    }

    .wishlist-page__primary-button,
    .product-line-card__price-subtotal-wrapper span {
        font-weight: var(--font-weight-bold);
    }

    .wishlist-page__primary-button {
        padding: 14px 32px;
        border: 1px solid var(--site-black);
    }

    .product-line-card__description-brand {
        font-size: calc(var(--body-font-size) + 1px) !important;
        color: var(--site-black);
    }
}

.wishlist-share .wishlist-share__close {
    border: none;
}

.wishlist-share {
    border-radius: 4px 4px 0 0;
}

.wishlist-share h1 span {
    font-size: var(--header-font-size);
}

.wishlist-share #share-wishlist-button {
    background-color: var(--site-accent);
    border: none;
}

.wishlist-share #share-wishlist-button:hover {
    background-color: var(--site-accent-secondary);
}

.wishlist-share__input-error {
    margin-top: -18px;
}

.wishlist-share .wishlist-share__input-email {
    color: var(--site-black);
}

.wishlist-page__header-share-button .global-icon {
    position: static;
    display: block !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--site-lightest-grey);
}

.wishlist-page__header-share-button .global-icon svg {
    padding: 8px;
    display: block;
    width: 40px;
    height: 40px;
}

.wishlist-page__header-text {
    margin: auto;
}

.wishlist-page {
    margin: 20px auto 0;
}

@media (min-width: 1021px) {
    .wishlist-page {
        margin: 40px auto 0;
    }
}
