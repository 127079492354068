.Browse .s-productscontainer2 {
    &:not(.swiper-wrapper) {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: calc(var(--padding-unit) * 4);
        margin: 0;

        @media (max-width: 1021px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    li {
        padding: 0;
        margin: 0;
        width: 100%;

        &:hover {
            .hotspotbuy.hotspotquickbuy {
                visibility: visible;
            }
        }

        @media (min-width: 1022px) {
            &:hover .s-productthumbbox {
                box-shadow:
                    0 1px 10px rgba(0, 0, 0, 0.025),
                    0 8px 25px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .s-productthumbbox,
    .s-productthumbbox:hover {
        padding: 0;
        border: none;
    }

    .s-productthumbbox {
        display: flex;
        flex-direction: column;
    }

    .s-productthumbimage {
        float: none;
        border-radius: 2px;
        border: none;
        padding: 0;
        overflow: hidden;

        .ProductImageList {
            margin-bottom: 0;
            max-width: 100%;

            img {
                width: 100%;
                height: 100%;
                transition: none;
                padding: calc(var(--padding-unit) * 3) calc(var(--padding-unit) * 3) 0 calc(var(--padding-unit) * 3);

                @media (max-width: 1021px) {
                    padding: 0;
                }
            }

            img::before {
                content: "";
                display: block;
                height: 100%;
            }

            &:focus img {
                transform: scale(1.02);
            }
        }

        &:hover .ProductImageList img {
            transform: none;
        }

        .s-product-sache {
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: auto !important;
        }

        .hotspotbuy.hotspotwishlist {
            display: block !important;
            top: 4px;
            right: 4px;
            width: 30px;
            height: 30px;
            background-color: var(--site-white);
            border-radius: 50%;

            .wishListSVG {
                width: 30px;
                height: 30px;
                padding: 0;

                .global-icon {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 100%;
                }

                svg {
                    position: absolute;
                    width: 20px;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &:hover svg path {
                stroke: var(--site-dark-grey);
            }

            &.addedWishList svg path {
                fill: var(--site-black);
            }

            &.addedWishList:hover svg path {
                fill: var(--site-dark-grey);
            }
        }
    }

    .TextSizeWrap {
        text-align: left;
        min-height: 111px;
        padding: 0 calc(var(--padding-unit) * 3);

        @media (max-width: 1021px) {
            padding: 0;
        }

        .s-productthumbtext {
            padding-top: calc(var(--padding-unit) * 3);

            @media (max-width: 1021px) {
                padding: 0;
            }
        }

        .s-producttext-top-wrapper {
            padding: 0;
            margin: 12px 0 3px;
            max-width: 100%;

            a:focus {
                text-decoration: underline;
            }

            .productdescriptionbrand,
            .productdescriptionname {
                color: var(--site-black);
            }

            .productdescriptionbrand {
                font-size: var(--subtitle-font-size);
                line-height: 27px;

                font-weight: var(--font-weight-regular);
                padding-bottom: 1px;
            }

            .productdescriptionname {
                font-size: var(--body-font-size);
                line-height: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                padding: 0;
            }
        }

        .s-productthumb-extra {
            font-size: 1em;
            padding: 0;
            margin-bottom: var(--padding-unit);

            .s-productsize {
                max-width: 100%;
                padding: 0;
            }

            .sizeText {
                display: none;
            }
        }

        .s-producttext-price {
            padding: 0 0 (var(--padding-unit) * 2.5);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: var(--site-black);
            max-width: 100%;

            .s-largered,
            .RefandPrice {
                padding: 0 var(--padding-unit) 0 0;
                display: flex;
                align-items: center;
            }

            .s-largered .curprice {
                font-size: var(--body-font-size);
                font-weight: var(--font-weight-bold);
                line-height: 20px;
                padding: 2px 0;
            }

            .s-producttext-withticket .s-largered {
                color: var(--site-red);
            }

            &.s-producttext-withticket .AdditonalPriceLabel,
            .RefandPrice span {
                font-size: var(--body-font-size);
                vertical-align: initial;
                font-weight: var(--font-weight-regular);
                color: var(--site-dark-grey);
                line-height: 1em;
            }

            .finance-monthly-payment {
                flex: 1 0 auto;
                max-width: 100%;
                color: #005a54;
            }

            /* BV Styling */
            .reviews-container {
                padding: 0;
                margin-top: 7px;
                margin-bottom: 0;
                min-height: 19px;
                width: 100%;

                .bv_numReviews_component_container .bv_text {
                    color: #00f;
                }

                .bv_numReviews_component_container {
                    padding: 5px 5px 6px;
                    background: #dde1e4;
                    border-radius: 25px;
                    text-align: center;
                }

                .bv_main_container {
                    display: flex;
                    align-items: center;
                }

                [data-bv-rating] .bv_main_container .bv_text,
                [data-bv-show="inline_rating"] .bv_main_container .bv_text {
                    line-height: 1 !important;
                    font-size: 12px !important;
                    padding: 0;
                    font-weight: var(--font-weight-bold);
                }

                .bv_stars_button_container {
                    padding: 0 5px 0 0;
                }

                .bv_stars_svg_no_wrap {
                    display: flex;
                }
            }
        }
    }

    .hotspotbuy.hotspotquickbuy {
        display: block !important;
        background-color: var(--site-white);
        right: 4px;
        top: 40px;
        width: 30px;
        height: 30px;
        border-radius: 50%;

        .QuickLookIcon {
            width: 30px;
            height: 30px;
            padding: 0;

            .global-icon {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
            }

            svg {
                position: absolute;
                width: 20px;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:hover svg path {
            stroke: var(--site-dark-grey);
        }

        .QuickLookText {
            display: block;
            font-size: var(--subtitle-font-size);
            text-transform: uppercase;
            font-weight: var(--font-weight-regular);
            color: var(--site-black);
        }

        &:hover .QuickLookText {
            color: var(--site-dark-grey);
        }
    }
}

.Browse .Responsive .s-largered span.fromProductPrice {
    font-size: 12px;
    font-weight: var(--font-weight-regular);
    padding-right: 4px;
}
