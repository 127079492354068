#HeaderGroup.ErrorHeader {
    height: 80px;

    .TopNavBar {
        border: none;

        .LogoWrap {
            width: 100%;
            padding: 10px 20px;
        }

        .container-fluid .row > div {
            height: 100%;
        }
    }

    & + .HeaderTopSpacer {
        height: 80px;
    }
}

.error-main {
    margin: 50px auto;
    padding: 0 calc(4 * var(--padding-unit));

    @media (min-width: 1022px) {
        padding: 0 calc(7.5 * var(--padding-unit));
    }

    h1 {
        font-size: 22px;
        text-transform: capitalize;
        font-weight: var(--font-weight-bold);
        margin-bottom: 20px;
    }

    p {
        font-size: 15px;
        padding-bottom: 10px;
    }

    .error-menu {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        max-width: 700px;
        margin-right: auto;

        a {
            background-color: var(--site-accent);
            border-radius: 4px;
            width: 100%;
            text-align: center;
            padding: 10px;
            transition: 300ms ease-out;

            &:first-of-type {
                margin-right: 4px;

                @media (max-width: 767px) {
                    margin: 0 0 5px;
                }
            }

            @media (min-width: 768px) {
                width: calc(50% - 4px);
            }

            &:hover {
                background-color: var(--site-accent-secondary);
            }

            & > span {
                display: inline-flex;
                font-size: 16px;
                font-weight: 700;
                text-transform: capitalize;
                color: var(--site-white);
                transition: 300ms ease-out;
            }
        }
    }

    .menu a > span {
        text-transform: capitalize;
    }
}

.error-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    padding: 0 calc(4 * var(--padding-unit));

    @media (min-width: 1022px) {
        padding: 0 calc(7.5 * var(--padding-unit));
    }

    @media (max-width: 768px) {
        justify-content: center;
    }

    a {
        position: relative;
        text-indent: -9999px;
        display: inline-block;
        margin-right: 12px;

        .u-vh {
            position: absolute;
        }

        svg {
            height: 30px;
            max-width: 30px;
        }
    }
}
