.Checkout .ContentWrapper {
    background-color: var(--site-white);
}

.OrderComplete {
    font-family: var(--default-font-family);

    .orderInformationWrapper {
        .order-complete-messages {
            h1,
            h2 {
                font-style: normal;
                font-weight: var(--font-weight-regular);

                em {
                    font-size: var(--header-font-size);
                }
            }

            .pii-item {
                font-size: var(--body-font-size);
            }

            a.button {
                font-family: var(--default-font-family);
                font-style: normal;
                text-transform: uppercase;
                display: block;
                line-height: 1em;
                letter-spacing: 1.4px;
                max-width: 320px;
            }
        }

        .order-details-wrapper {
            .order-details-section {
                h2 {
                    font-weight: var(--font-weight-extra-bold);
                    font-size: var(--subtitle-font-size);
                }

                .order-details-title {
                    font-size: calc(var(--body-font-size) + 1px);
                    font-weight: var(--font-weight-bold);
                }

                p {
                    font-size: var(--body-font-size);
                    font-weight: var(--font-weight-regular);
                }

                .order-details-delivery-date span {
                    color: var(--site-black);
                    font-style: normal;
                }
            }
        }
    }

    .order-summary {
        h2 {
            font-size: var(--header-font-size);
        }

        .product-gallery .swiper-button-next,
        .product-gallery .swiper-button-prev {
            background-color: var(--site-dark-grey);
            border: 1px solid var(--site-dark-grey);

            .st1 {
                fill: var(--site-white);
            }
        }
    }

    .RegisterWrapper {
        padding-top: 0;

        .RegisterWrapper-inner {
            font-family: var(--default-font-family);

            .OrderCompleteRegistration {
                .OrderCompleteRegistrationForm {
                    input[type="submit"] {
                        background-color: var(--site-white);
                        color: var(--site-black);
                        font-style: normal;
                        border: 0;
                        display: block;
                        line-height: 1em;
                        letter-spacing: 1.4px;
                        max-width: 320px;
                        border-radius: 2px;
                        padding: 15px;

                        @media (min-width: 768px) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.RegisterWrapper {
    padding-top: 0 !important;
}

.RegisterWrapper .RegisterWrapper-inner {
    font-family: var(--default-font-family);
}

.footer-nav {
    justify-content: center;
}

.OrderCompleteRegistration .SignUpLabel {
    font-size: var(--body-font-size);
}

.OrderComplete.Checkout22 h2 {
    font-weight: var(--font-weight-extra-bold);
    color: var(--site-black);
    margin: 0 0 22px;
}

.Checkout22 .OrderCompleteRegistration .ContinueOn {
    background-color: var(--site-cta-background);
    color: var(--site-cta-text);
    border: 1px solid var(--site-cta-background);
    border-radius: 25px;
    font-style: normal;
    display: block;
    line-height: 1em;
    letter-spacing: 1.4px;
    padding: 15px;
    max-width: 230px;
}

.OrderCompleteRegistration input[type="submit"].ContinueOn:hover {
    background-color: var(--site-cta-background-hover) !important;
    color: var(--site-cta-text-hover) !important;
}

.OrderComplete.Checkout22 a.button.secondary {
    font-family: var(--default-font-family);
    font-style: normal;
    border: 1px solid var(--site-black) !important;
    background-color: var(--site-white) !important;
    color: var(--site-black) !important;
    padding: 16px 32px;

    &:hover {
        background-color: var(--site-black) !important;
        color: var(--site-white) !important;
    }
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader h2 {
    font-size: var(--body-font-size);
}

.order-summary-table {
    text-align: left;
}

.order-summary .product-gallery .swiper-button-next:focus,
.order-summary .product-gallery .swiper-button-next:hover,
.order-summary .product-gallery .swiper-button-prev:focus,
.order-summary .product-gallery .swiper-button-prev:hover {
    background-color: var(--site-dark-grey) !important;

    .st1 {
        fill: var(--site-white) !important;
    }
}

.registration-benefit .global-icon svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.OrderComplete.Checkout22 a.button {
    background-color: var(--site-cta-background) !important;
    border: 1px solid var(--site-cta-background) !important;
    color: var(--site-cta-text) !important;
    padding: 16px 32px;
    font-style: normal;
    font-family: var(--default-font-family);
    transition: all 0.3s ease-in-out;
    border-radius: 25px;

    &:hover {
        background-color: var(--site-cta-background-hover) !important;
        color: var(--site-cta-text-hover) !important;
    }
}

@media (max-width: 767px) {
    .OrderComplete.Checkout22 a.button.secondary {
        width: 100%;
    }

    .Checkout22 .OrderCompleteRegistration .ContinueOn {
        max-width: 100%;
    }

    .OrderCompleteRegistrationForm input[type="submit"] {
        max-width: 100%;
    }

    .OrderComplete .orderInformationWrapper .order-complete-messages a.button {
        max-width: 100%;
    }

    .OrderComplete.Checkout22 a.button {
        display: inline-block;
        min-width: 100%;
    }
}
