@font-face {
    font-family: RobotoCondensed;
    src: url("/Fonts/RobotoCondensed-Regular.woff2") format("woff2");
}

@font-face {
    font-family: RobotoCondensed;
    src: url("/Fonts/RobotoCondensed-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: RobotoCondensed;
    src: url("/Fonts/RobotoCondensed-ExtraBold.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
