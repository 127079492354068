#CheckoutSpaRoot header {
    background-color: var(--site-black);
}

#CheckoutSpaRoot .CheckoutHeader .headerSecureCheckout {
    color: var(--site-white) !important;
}

#CheckoutSpaRoot header .headerRightSecureCheckout .secureText {
    color: var(--site-white);
}

.checkChevron span {
    background-position: -16px -115px;
}

.OrderCompleteLogin {
    max-width: 660px;
    margin: 0 auto 40px;
}

.OrderCompleteLogin .NewCustWrap .ImgButWrap {
    display: block;
}

.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
    font-weight: var(--font-weight-regular);
    padding: 14px 10px;
}

.OrderComplete.Checkout22 h1,
.OrderComplete .orderInformationWrapper .order-complete-messages a.button {
    text-transform: capitalize;
}

.Checkout22:not(.orderCompleteRegisterShown) .OrderCompleteRegistration {
    border: 1px solid #e5e5e5;
    padding: 22px;

    .OrderCompleteRegisteredSignUp {
        display: block;
        border: none;

        .OrderCompleteRegistrationFormInner {
            padding: 0;
        }
    }
}
