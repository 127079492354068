.Basket .elevated-cart.elevated-cart-is-empty .cart-page__primary-button {
    padding: 14px 32px;
    border: var(--cart-button-border);

    &:hover {
        background-color: var(--site-cta-background-hover);
    }
}

.product-line-card__description-brand {
    font-size: calc(var(--body-font-size) + 1px);
    font-weight: var(--font-weight-bold);
}

.basket-summary-continue-button-container .ContinueOn {
    padding: 13px 32px;
}

.Basket .PromoCodeInput input {
    height: 44px;
}

.Basket .elevated-cart .basket-summary-header,
.Basket .elevated-cart .PromoCodeBut {
    text-transform: capitalize;
}
