#ageVerificationPopup .modal-dialog,
#SocialModal .modal-dialog {
    translate: 0 -50%;
    top: 50%;
    margin-top: 0 !important;
}

#ageVerificationPopup {
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        p {
            margin-bottom: 20px;
            font-size: 1.2em;
            margin-top: 10px;
            order: 1;
        }

        .ageVerificationOk {
            text-transform: capitalize;
            padding: 15px 30px;
            display: inline-block;
            font-size: 1.2em;
            font-weight: bold;
            background-color: var(--site-cta-background);
            border: 1px var(--site-cta-background) solid;
            color: var(--site-white);
            letter-spacing: 0.1em;
            border-radius: 5px;
            order: 2;

            &:hover {
                background-color: var(--site-cta-background-hover);
                border-color: var(--site-cta-background-hover);
                color: var(--site-white);
            }
        }

        .ageVerificationCancel {
            order: 3;
            margin: 20px 0 0;
            display: block;
            font-size: 1rem;
            text-decoration: underline;
        }
    }
}
