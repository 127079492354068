html:has(.cms-placements),
html:has(.is-cms-content-page) {
    scrollbar-gutter: unset !important;
}

.is-cms-content-page .ContentWrapper {
    padding: 0;
    max-width: 100%;
}

.cms-placements {
    display: block;
    position: relative;
    max-width: 1920px;

    [class*="Carousel_slides_"] {
        padding-bottom: var(--spacing-6);

        @media (min-width: 768px) {
            padding-bottom: var(--spacing-10);
        }
    }
}

body {
    box-sizing: border-box;
}

#__next,
.cms-placements {
    #content-x div {
        &[data-testid="container"],
        &[data-testid="club-finder-block"],
        &[data-testid="tabbed-quick-links-block"],
        &[data-testid="brand-block-container"],
        &[data-testid="cards-block"] {
            margin: auto;
        }
    }

    h1,
    h1 span,
    h2,
    h3,
    h4,
    h5,
    p,
    label,
    input,
    optgroup,
    select,
    textarea {
        color: var(--color);
    }

    div[data-testid="markdown-block"] img {
        max-width: 100%;
    }

    ul,
    ul li {
        list-style: initial;
    }

    ol,
    ol li {
        list-style: decimal;
    }

    ul,
    ol {
        padding-left: var(--spacing-4, 16px);
    }

    [class*="Button"] {
        border-radius: 20px;
    }

    p {
        font-size: 14px;
    }

    h2 {
        font-size: 28px;
    }

    [class*="HeroBlock_overlaid"] h2,
    [class*="HeroBlock_overlaid"] p {
        color: var(--common-white);
    }

    [class*="FeaturedBlock_text"] h2 {
        text-transform: none;
    }

    [class*="FeaturedBlock_buttonGroup"] a {
        border-color: var(--primary-main);
        background: var(--primary-main);
    }

    [class*="Typography_subheading2"] {
        font-size: 16px;
        font-weight: 700;
    }

    [class*="Typography_subheading2"]:hover {
        color: inherit;
    }

    [class*="MarkdownBlock_center"] {
        margin: 0 auto;
    }

    [class*="FeaturedBlock_buttonGroup"] a:hover {
        border-color: var(--primary-dark);
        background: var(--primary-dark);
    }

    [class*="FeaturedBlock_text"] [class*="FeaturedBlock_subtitle"] {
        font-size: 18px;
        font-weight: 400;
    }

    [class*="Button_primary"] {
        border-color: var(--primary-main);
        background: var(--primary-main);
        color: var(--common-white);
    }

    [class*="Button_primary"]:hover {
        border-color: var(--primary-dark);
        background: var(--primary-dark);
        color: var(--common-white);
    }

    [class*="Button_black"] {
        border-color: var(--common-black);
        background: var(--common-black);
        color: var(--common-white);
    }

    [class*="Button_black"]:hover {
        border-color: var(--primary-main);
        background: var(--primary-main);
        color: var(--common-white);
    }

    [class*="Button_secondary"] {
        border-color: var(--common-black);
        background: var(--common-white);
        color: var(--common-black);
    }

    [class*="Button_secondary"]:hover {
        border-color: var(--primary-main);
    }

    [class*="Button_sale"]:hover {
        background-color: var(--secondary-main);
    }

    [class*="ReadMore_button"]:hover {
        background-color: var(--primary-main);
    }
}
