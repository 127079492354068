/* Header Overrides */
a,
a:active,
a:focus,
a:link,
a:visited {
    color: var(--site-black);
}

#HeaderGroup {
    border: none;
    height: 123px;
}

.HeaderTopSpacer {
    height: 123px;
}

#topMenuWrapper,
.TopNavBar {
    background-color: var(--site-black);

    @media (min-width: 1022px) {
        border-bottom: none;
    }
}

.ToplinksGroup .search #txtSearch {
    font-size: var(--subtitle-font-size);
    background-color: var(--site-black);
    color: var(--site-white);
    border-color: var(--site-black) var(--site-black) var(--site-white) var(--site-black);
    border-radius: 0;

    @media (max-width: 767px) {
        height: 45px;
    }

    &::-webkit-input-placeholder {
        color: var(--site-white) !important;
    }

    &::-moz-placeholder {
        color: var(--site-white) !important;
    }

    ~ .search-dropdown__perform-search .global-icon-search svg {
        circle {
            stroke: var(--site-white);
        }

        path {
            fill: var(--site-white);
        }
    }

    &:focus {
        color: var(--site-black);
        border-color: var(--site-white);

        & ~ .search-dropdown__perform-search .global-icon-search svg {
            circle {
                stroke: var(--site-black);
            }

            path {
                fill: var(--site-black);
            }
        }
    }
}

.ToplinksGroup .search input::-webkit-input-placeholder,
.ToplinksGroup .search textarea::-webkit-input-placeholder {
    color: var(--site-white);
}

.ControlWrap {
    .global-icon:not(.global-icon-tick) svg path,
    .global-icon svg polygon {
        stroke: var(--site-white);
        fill: transparent;
    }

    .global-icon.global-icon-menu svg path {
        fill: var(--site-white);
    }

    .wishlist-summary,
    .elevated-sliding-basket {
        .global-icon:not(.global-icon-tick, .global-icon-bag, .global-icon-wishlist) svg path {
            stroke: var(--site-black);
            fill: var(--site-black);
        }

        .global-icon-wishlist svg path,
        .global-icon-bag svg path,
        .global-icon-bag svg polygon {
            stroke: var(--site-black);
        }
    }

    @media (min-width: 768px) {
        #divBagTotalLink a#aBagLink:hover .global-icon svg path,
        #divBagTotalLink a#aBagLink:hover .global-icon svg polygon,
        #divWishList.WishList #aWishListLink:hover .global-icon svg path,
        .accountBlock .login:hover .global-icon svg path,
        .accountBlock:hover .global-icon svg path,
        .storeFinder a:hover .global-icon-store svg path {
            stroke: var(--site-white);
        }

        #divWishList.WishList #aWishListLink:hover .global-icon svg path,
        #divBagTotalLink a#aBagLink:hover .global-icon svg polygon,
        .accountBlock .login:hover .global-icon svg path,
        .accountBlock:hover .global-icon svg path,
        .storeFinder a:hover .global-icon-store svg .outer-shape {
            fill: var(--site-white);
        }

        .storeFinder a:hover .global-icon-store svg .inner-shape {
            fill: var(--site-black);
        }
    }
}

.lillBasket #divBagItems {
    #aViewBag {
        &:hover {
            background-color: var(--site-cta-background) !important;
        }
    }

    #aCheckout {
        padding: 14px;
        border: 1px solid var(--site-black);

        &:hover {
            background-color: var(--site-cta-background-hover);
        }
    }
}

#divWishList .wishlist-summary .wishlist-summary-footer {
    .ctaBtn:hover {
        background-color: var(--site-cta-background) !important;
    }

    .ctaBtn:focus {
        outline: none !important;
    }

    .ctaBtn:not(.signIn):hover {
        background-color: var(--site-cta-background-hover) !important;
    }
}

.search-dropdown__wrapper {
    padding-bottom: 50px;
}

body.logged-in #topLinkMenu ul li.TopLink > a > .ico,
#topLinkMenu ul li.TopLink > a:hover > .ico {
    background-position: -7px -414px;
}

.LogoWrap a {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 151px;

    @media (min-width: 1022px) {
        max-width: 200px;
    }

    @media (min-width: 768px) and (max-width: 1021px) {
        max-width: 128px;
    }

    svg path {
        fill: var(--site-white);
    }
}

.sitewide-banner-enabled {
    --sitewide-banner-height: 50px;
}

.sitewide-banner > .sitewide-banner-slide a {
    white-space: normal;
    padding: 0 15px;
    font-size: var(--body-font-size);
    @media (max-width: 767px) {
        line-height: 18px;
    }
}

.store-finder-component {
    a:hover {
        svg path.outer-shape {
            fill: var(--site-black);
        }

        svg path.inner-shape {
            stroke: var(--site-white);
        }
    }
}

#StoreFinderListWrapper {
    .StoreFinderResultsLink {
        color: var(--site-accent);
        font-size: var(--body-font-size);
    }
}

.store-fields-a-z {
    .button-search input,
    .clear-but input {
        border: none;
        background-color: var(--site-cta-background);
        color: var(--site-cta-text);
        text-transform: none;
        font-weight: var(--font-weight-bold);

        &:hover {
            background-color: var(--site-cta-background-hover);
        }
    }
}

.StoreLocator .storefinderSearchButtons input {
    border: none;
    background-color: var(--site-cta-background);
    color: var(--site-cta-text);
    text-transform: none;
    font-weight: var(--font-weight-bold);

    &:hover {
        background-color: var(--site-cta-background-hover);
    }
}

#divBagTotalLink a#aBagLink #bagQuantityContainer .global-icon-bag svg,
.ControlWrap .WishList .wishQuantityContainer .global-icon-wishlist svg {
    width: 26px;
}

.SignInLink .login .userLoginContainer .global-icon-account svg {
    width: 22px;
}

.ControlWrap .WishList #lblWishListCount,
.lillBasket #aBagLink span#bagQuantity {
    top: -6px;
    left: 45%;
    font-weight: var(--font-weight-bold);
}

.ControlWrap .WishList #lblWishListCount {
    top: -8px;
}

.search-dropdown__innercontainer {
    .search-dropdown__wrapper {
        h3 {
            font-size: 16px;
            color: #000;
        }

        .search-suggestions-dropdown__section--product__brand {
            font-size: 15px;
            font-weight: bold;
            color: #000;
            padding: 5px 0;
        }

        .search-suggestions-dropdown__section--product__name {
            margin-bottom: 5px;
        }
    }

    @media (min-width: 1022px) {
        border-radius: 0;
    }
}

.footerContainer .footerContainerBottom .footerLanguageSwitcher .footerLogo svg {
    max-height: 21px;
}

@media (max-width: 1021px) {
    .FooterWrap .footerContainer .footerMenu .FooterSubGroup span {
        background-position: -400px -385px;
    }

    .FooterWrap .footerContainer .footerMenu .FooterSubGroup .FooterHeader span.glyphicon-chevron-down {
        background-position: -439px -386px;
    }
}

.u-vh {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.sitewide-banner-container {
    .Basket & {
        margin-bottom: 16px;
    }
}

/* Footer Overrides */
@media (max-width: 767px) {
    .FooterWrap .footerContainer .footerContainerBottom {
        .socialSitesWrapper {
            flex-wrap: wrap;
            gap: 15px;

            > span {
                margin-top: 20px;
                width: 100%;
                text-align: center;
            }

            a {
                margin-left: 0;
            }
        }

        .footerLanguageSwitcher {
            gap: 25px;
        }
    }
}

.FooterWrap {
    border: none;
}

.FooterWrap,
.FooterWrap .FooterTop {
    background-color: var(--site-black);
}

.FooterGroup,
.FooterGroup::after,
.FooterGroup::before {
    border: none;
}

.FooterGroup::after,
.FooterGroup::before {
    display: none;
}

.footerContainer .footerContainerTop {
    flex-direction: column;
}

.footerContainer .footerContainerTop .footerQuickLinks {
    flex-grow: 0;
    flex-basis: 60%;
}

.FooterWrap .footerContainer .footerContainerBottom {
    margin-bottom: 20px;

    @media (max-width: 1021px) {
        margin-bottom: 0;
        row-gap: 10px;
    }

    @media (max-width: 767px) {
        gap: 24px;
    }

    .footerLanguageSwitcher .footerLogo a {
        display: flex;
    }

    .Copyright .footerCopyLine span {
        color: var(--site-white);
    }

    .socialSitesWrapper {
        span {
            font-size: calc(var(--body-font-size) - 2px);
        }

        a {
            margin-left: 24px;

            svg {
                max-width: 100%;
                height: 32px;
            }
        }
    }
}

.FooterWrap .FooterTop,
.footerContainer .footerContainerTop,
.footerContainer .footerContainerBottom {
    max-width: var(--large-container-max-width);
}

.currencyLanguageSelector .spanCurrencyLanguageSelector {
    .editCountryCurrency {
        width: auto;
        height: auto;
        text-indent: 0;
        background-image: none;
        transform: none;
    }

    p,
    .editCountryCurrency {
        color: var(--site-white);

        &:hover {
            text-decoration: underline;
        }
    }
}

.FooterWrap .footerSignup {
    width: 100%;

    @media (min-width: 1022px) {
        display: flex;
        max-width: 600px;
        align-items: center;
    }

    .signup-wrapper {
        width: 100%;

        .signupInner {
            align-items: flex-start;

            @media (min-width: 1022px) {
                padding: 0;
                margin: 0;
            }

            @media (min-width: 769px) and (max-width: 1021px) {
                width: 75%;
            }

            .SubTitle {
                color: var(--site-white);
                font-size: calc(var(--subtitle-font-size) + 2px);
            }

            #signupEmail,
            .signupInputBox {
                border-radius: 0;
            }

            #signupEmail {
                height: 44px;
                opacity: 1;
                border-color: var(--site-white);
            }

            .signupInputBox {
                .NewsletterPadding {
                    width: 100%;
                }
            }

            #enterNewsLetter {
                height: 44px;
                background-color: var(--site-white) !important;
                color: var(--site-black) !important;
                border-radius: 0;

                &:hover {
                    background-color: var(--site-cta-background-hover);
                }
            }

            .disclaimerText {
                display: block;
            }

            .disclaimerText p {
                color: var(--site-white);
                font-size: calc(var(--body-font-size) - 2px);

                a {
                    color: var(--site-white);
                }
            }
        }
    }
}

.FooterSubGroup .FooterHeader,
.FooterGroupLink li a {
    color: var(--site-white);
}

@media (max-width: 1021px) {
    .MobileMenuContentWrap .shop {
        padding: 0;
    }

    /* Footer Overrides */
    .FooterWrap .footerContainer {
        .footerContainerTop {
            padding: 0;

            .footerQuickLinks {
                padding: 15px 0 0;
            }
        }

        .footerMenu {
            flex-direction: column;
            padding: 0;

            > div {
                flex-basis: 100%;

                &:first-of-type {
                    border-top: 1px solid var(--site-mid-grey);
                }
            }

            &.row,
            .row {
                margin: 0;
            }

            .col-xs-12,
            .col-sm-3 {
                padding: 0;
            }

            .FooterSubGroup {
                border-bottom: 1px solid var(--site-mid-grey);

                span {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 18px;
                    background-position: -400px -385px;
                    background-size: 900px 900px;
                    right: 15px;
                    padding: 0;
                }

                .FooterHeader span.glyphicon-chevron-down {
                    background-position: -439px -386px;
                }

                span::before {
                    display: none;
                }
            }

            .FooterHeader {
                font-size: var(--body-font-size);
                padding: 15px;
                position: relative;
                cursor: pointer;
            }

            .FooterGroupLink li a {
                padding: 5px 15px;
            }
        }
    }
}

@media (max-width: 767px) {
    .FooterWrap .footerContainer {
        .footerMenu {
            padding: 20px 0;
        }
    }
}

@media (min-width: 1022px) {
    .footerContainer .footerMenu {
        padding: 40px 0;
        justify-content: space-evenly;
        gap: 15px;

        .FooterSubGroup .FooterHeader {
            display: none;
        }
    }
}

#NewsLetterModal {
    .modal-header {
        padding: 10px 40px 10px 20px;
    }

    &.modal .modal-header .text-center {
        display: flex;
        align-items: center;

        span {
            color: #000;
        }
    }

    input,
    select {
        border-radius: var(--padding-unit);
    }

    .form-wrapper .SubmitButton input,
    .thankyou-wrapper input {
        background-color: var(--site-accent-secondary);

        &:hover {
            background-color: var(--site-cta-background-hover);
        }
    }
}

/* Button cta overrides */
.NewCustWrap .ImgButWrap a span,
.ProfileWrap .saveBtn #btnSaveChanges a span,
#divWishList .wishlist-summary .wishlist-summary-footer .ctaBtn span,
#divBagItems #divButtons #aViewBag span,
.wishlist-page .wishlist-page__primary-button span,
#CheckoutSpaRoot button {
    display: block;
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}

.Login .NewCustWrap a:focus {
    background-color: var(--site-black) !important;
}

.NewCustWrap .ImgButWrap,
.ProfileWrap .saveBtn #btnSaveChanges {
    border-radius: var(--border-radius);
}

/* Bag Overrides */
#divBagItems {
    @media (min-width: 768px) {
        .lillBasket & {
            top: 70px;
        }
    }

    .product-line-card__description-subtotal-amount {
        font-weight: var(--font-weight-bold);
    }

    .product-line-card__description-pre-order {
        color: var(--product-line-item-line-pre-order-color);
    }

    #divButtons {
        #aViewBag {
            font-weight: var(--font-weight-bold);
            border-color: var(--site-black);

            span {
                color: var(--site-black);
            }

            &:hover span {
                color: var(--site-white);
            }
        }
    }
}

.Basket .elevated-cart {
    &.elevated-cart-is-empty {
        .global-icon.global-icon-bag {
            svg {
                width: 24px;
            }
        }
    }

    .basket-summary {
        &-header {
            text-transform: uppercase;
        }
    }

    .product-line-card {
        &__price-subtotal-wrapper {
            span {
                font-weight: var(--font-weight-bold);
            }
        }
    }
}

/* Checkout & Order Confirmation Overrides */
#CheckoutSpaRoot {
    .formCompleteCTA.ctaLoading button::after {
        border-radius: var(--site-cta-border-radius);
    }
}

.OrderComplete.Checkout22 {
    .order-complete-messages h2 {
        font-weight: var(--font-weight-bold);
    }

    a.button,
    .OrderCompleteRegistration .ContinueOn {
        border-radius: var(--site-cta-border-radius);
    }
}

.OrderGroup .OrderColC .ButtonOrder a {
    color: var(--site-white);
    border-radius: var(--site-cta-border-radius);
}

.OrderDetail .ImgButWrap a {
    background-color: var(--site-cta-background);
    color: var(--site-cta-text);
    font-weight: var(--font-weight-bold);
    border-radius: var(--site-cta-border-radius);

    &:hover {
        background-color: var(--site-cta-background-hover);
    }
}

@media (min-width: 1022px) {
    .TopNavBar .ControlWrap {
        padding: 0;
    }

    /* Footer Overrides */
    .footerContainer .footerContainerTop {
        flex-direction: row;
        gap: 15px;
        border-bottom: 1px solid var(--site-mid-grey);
    }
}

.hotspotbuy.hotspotwishlist .wishListSVG {
    .global-icon svg {
        path,
        polyline.icon-hover {
            stroke: var(--site-dark-grey);
        }
    }

    &:hover {
        .global-icon svg {
            path,
            polyline.icon-hover {
                stroke: var(--site-black);
            }
        }
    }
}

.Browse .s-productscontainer2 .hotspotbuy.hotspotwishlist {
    .global-icon {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.Browse .s-productscontainer2 .hotspotbuy.hotspotquickbuy {
    svg path {
        stroke: var(--site-dark-grey);
    }

    &:hover svg path {
        stroke: var(--site-black);
    }
}

@media (min-width: 768px) {
    /* Header Overrides */
    .ControlWrap .accountBlock .login .global-icon svg .icon-mask,
    .ControlWrap .accountBlock .global-icon svg .icon-mask,
    .ControlWrap .accountBlock .login:hover .global-icon svg .icon-mask,
    .ControlWrap .accountBlock:hover .global-icon svg .icon-mask {
        fill: var(--site-white);
    }

    #divBagTotalLink a#aBagLink:hover .global-icon svg path,
    .ControlWrap .WishList #aWishListLink:hover .global-icon svg path,
    .ControlWrap .accountBlock .login:hover .global-icon svg .icon-hover,
    .ControlWrap .accountBlock:hover .global-icon svg .icon-hover {
        stroke: var(--site-black);
        fill: var(--site-black);
    }

    .ControlWrap .WishList #aWishListLink:hover .global-icon svg .icon-hover,
    .hotspotbuy.hotspotwishlist .wishListSVG:hover .global-icon svg .icon-hover {
        stroke: var(--site-white);
    }

    /* PDP Overrides */
    .sdDetails .productImageContentWrapper #productImages,
    .sdDetails .productImageContentWrapper #productDetails {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    /* Footer Overrides */
    .FooterWrap .footerContainer .footerContainerTop {
        .footerQuickLinks {
            width: calc(100% - 30px);
            margin: 0 auto;
        }
    }
}

/* Megamenu overrides */
nav#topMenu {
    ul li.root > a {
        color: var(--site-white);
    }

    ul li.root.sdHover > a {
        color: var(--site-white);
        text-decoration: underline;
    }
}

@media (min-width: 768px) {
    nav#topMenu ul {
        li.root > a {
            padding: 0 5px;
        }
        li.MenuGroupSale > a {
            padding: 0 20px;
        }
    }
}

nav#topMenu ul li.root.fourCol .SubMenuWrapper ul li.Center > ul > li {
    width: 25%;
}

nav#topMenu ul li.root.threeCol .SubMenuWrapper ul li.Center > ul > li {
    width: 33.3333%;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper {
    min-height: 300px;

    > ul > li > ul {
        gap: 30px;
    }

    .Center > ul li.level1.sdmColHeader {
        width: auto;
        padding: 25px 0 0;

        &.activeItem > a {
            color: var(--site-accent);
        }
    }

    > ul {
        min-height: 300px;
        padding: 0;

        li {
            &.mmHasChild.level1 {
                & > ul {
                    min-height: 0;
                }

                & > a {
                    text-transform: capitalize;
                    padding: 0 10px;
                    text-align: center;
                    min-width: 0;

                    &::before {
                        display: none;
                    }
                }
            }

            &.Center {
                max-width: none;
                padding-top: var(--padding-unit);
            }
        }
    }
}

nav#topMenu ul li.root.sidebarSubMenu.promoEnabled .SubMenuWrapper ul li.Center > div.Center {
    display: none;
}

@media (min-width: 1500px) {
    nav#topMenu ul li.root.sidebarSubMenu.promoEnabled .SubMenuWrapper > ul {
        min-height: 450px;

        li.Center > div.Center {
            position: absolute;
            width: 16.666667%;
            padding: 0;
            margin-top: 85px;
            z-index: 100000;
            right: 4.5%;
            display: block;

            .stackFlexHeader {
                padding: 20px 15px 10px;
            }

            > div {
                width: 100%;

                &.twoPromoRight1 {
                    margin-top: 0;

                    .twoStackItem {
                        padding: 0 10px 10px;
                    }
                }
            }
        }
    }
}

/*Megamenu tagging*/
.mp-menu > .mp-level ul li.gameNewTag .menuitemtext::after,
nav#topMenu > ul li.gameNewTag::after {
    display: inline-block;
    content: "NEW";
    padding: 4px;
    margin-top: 10px;
    margin-left: 8px;
    border-radius: 3px;
    vertical-align: top;
    font-size: 0.8em;
    line-height: 1em;
    color: #fff;
    background: var(--site-accent);
    @media (min-width: 1022px) {
        margin-top: 5px;
    }
}

nav#topMenu li.root .SubMenuWrapper > ul li.gameNewTag > a {
    width: auto;
    max-width: calc(100% - 50px);
    display: inline-block;
}

.mp-menu > .mp-level ul li.lastChanceTag .menuitemtext::after,
nav#topMenu > ul li.lastChanceTag::after {
    display: inline-block;
    content: "LAST CHANCE";
    padding: 4px;
    margin-top: 10px;
    margin-left: 8px;
    border-radius: 3px;
    vertical-align: top;
    font-size: 0.8em;
    line-height: 1em;
    color: #fff;
    background: var(--site-accent);
    @media (min-width: 1022px) {
        margin-top: 5px;
    }
}

nav#topMenu li.root .SubMenuWrapper > ul li.lastChanceTag > a {
    width: auto;
    max-width: calc(100% - 104px);
    display: inline-block;
}

.gameArea .AltProdDet .colourChooser .s-productextras-column-1:before,
.gameArea .AltProdDet .swapSize .s-productextras-column-1:before {
    content: "Format: ";
    font-weight: 700;
    font-size: 1.2em;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    display: inline-block;
    cursor: text;
}

.gameArea .AltProdDet .colourChooser .s-productextras-column-1,
.gameArea .AltProdDet .swapSize .s-productextras-column-1 {
    position: relative;
}

.gameArea .AltProdDet .swapSize .s-productextras-column-1:before {
    content: "Edition: ";
}

.gameArea .AltProdDet .s-productextras-column-1 #BuyColourText:after,
.gameArea .AltProdDet .s-productextras-column-1 #BuySizeText:after {
    content: none;
}

.gameArea .AltProdDet .colourChooser .s-productextras-column-1 #BuyColourText,
.gameArea .AltProdDet .swapSize .s-productextras-column-1 .BuySizeText {
    left: 0;
    top: 0;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.gameArea .AltProdDet #divPopupSizeGuide {
    display: none;
}

/* Search Overrides */
@media (max-width: 1021px) {
    .dvSearch #searchSuggestionsDropdown[data-visible="yes"] {
        border-radius: 0;
        top: var(--top);
        width: 100%;
        left: 0;
        max-height: calc(100vh - var(--top) - 1px);
        border-top: 1px solid #c4c4c4;
    }
}

/*Mobile Menu Overrides*/

.MobileMenuContentWrap {
    .mobMenuGroup {
        padding-top: 0 !important;

        li {
            border-bottom: 2px solid var(--site-mid-grey);
        }

        li.panel {
            margin-bottom: 0;
        }

        .shop > ul > li .menulevelheader > a,
        .mobMenuGroup #mob-myaccount .menulevelheader a {
            text-transform: unset;
        }

        &.account-menu {
            li:last-of-type {
                border-bottom: none;
            }
        }
    }
}

#mp-menu .mp-level .menuitemtext {
    font-weight: var(--font-weight-bold);
}

#mp-menu .mp-level .MenuGroupSale,
#mp-menu .mp-level .MenuGroupSale > .menuitemtext.MobMenChevron,
nav#topMenu ul li.root.MenuGroupSale.sdHover > a,
nav#topMenu ul li.root.MenuGroupSale > a,
#mp-menu .mp-level .MenuGroupSale > .menuitemtext.MobMenChevron::after {
    background-color: #ed0302;
    color: var(--site-white);
}

#homeMenu {
    text-align: center;
}

.menulevelheader {
    display: flex;
    justify-content: center;
    padding: 0 10px 0 10px;
}

@media (max-width: 1021px) {
    .mp-menu .mp-level .shop a.MobMenChevron::after {
        background-position: -393px -362px;
    }

    .mp-menu .mp-level .shop .mobile-menu-footer-content a.MobMenChevron::after {
        background-position: -334px -362px;
    }

    #mp-menu ul li.mobMenToplink {
        position: absolute;
        margin: 0;
        top: 18px;
        right: 25px;
        width: auto;
        background-color: transparent;
        height: auto !important;
        border: 0;

        a.menuitemtext {
            color: var(--site-black);
            padding: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            background-color: transparent;
            font-size: var(--body-font-size);
            border-bottom: 0;
            line-height: 1.4em;
            margin: 0;
            font-weight: var(--font-weight-regular);
            width: auto;
        }
    }

    .am-menu .am-level a.MobMenChevron::after,
    .mp-menu .mp-level a.MobMenChevron::after {
        background-size: 750px 750px;
        background-position: -330px -362px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    #divCurrencyLanguageMobile,
    .MobileMenuContentWrap > .mobMenuGroup,
    .MobileMenuContentWrap > .mobMenuGroup ~ ul,
    .mp-menu > .mp-level .mobile-menu-footer-content > .account-menu > li.root {
        background-color: var(--site-light-grey);
    }

    #dnn_mobLogin_loginLink,
    #liMobileCurrencySelector,
    #liMobileLanguageSelector {
        border-top: 2px solid var(--site-mid-grey);
    }

    .menu-trigger {
        &:hover {
            background-color: var(--site-black) !important;
        }

        .global-icon {
            pointer-events: none;
        }
    }

    #mp-menu .mp-level .MenuGroupSale .menuitemtext > img {
        border: 1px solid var(--site-white);
    }
}

@media (max-width: 767px) {
    #mp-menu {
        top: 123px !important;
        height: calc(100% - 123px);
    }
}

#PersonalisationModal .popupPersonalisation.is-evoucher .modal-body {
    background-color: var(--site-white);

    .PersVouchBasketContainer {
        margin-top: 20px;
    }

    #btnAddEVoucherToBasket {
        background-color: var(--site-cta-background);
        color: var(--site-white);
        border-radius: var(--padding-unit);
        transition: all 0.25s linear 0s;

        &:hover {
            background-color: var(--site-cta-background-hover);
        }
    }
}

.Copyright .footerCopyLine a {
    color: var(--site-white);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

/* Brand Page Overrides */
@media (max-width: 767px) {
    .Responsive .lettersBoxesColumn tr {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .Responsive .lettersBoxesColumn tr .letTilte,
    .Responsive .lettersBoxesColumn td:last-of-type {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .Responsive .lettersBoxesColumn td {
        width: 100%;
    }
}

.lettersBoxesColumn .letItems {
    font-size: calc(var(--body-font-size) - 2px);
}

/* Pre-Order Popup Overrides */
.popupPreorderPopup .preOrderbuttons .preorderCancelContinueButton {
    background-color: var(--site-cta-background);
    color: var(--site-cta-text);
    font-weight: var(--font-weight-bold);

    &:hover {
        background-color: var(--site-cta-background-hover);
    }
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap a.preorderCancelButton {
    border: none;
    background-color: var(--site-black) !important;
    font-weight: var(--font-weight-bold);

    span {
        color: var(--site-white) !important;
    }
}

@media (max-width: 767px) {
    #PreorderPopupModal .popupPreorderPopup .modal-body .divPreorder .addToBasketContainer .ImgButWrap {
        padding: 0;
        margin-bottom: 12px;
    }
}

/* Scrollbar Overrides */
#divsiblinglistwrapper ul.ulFirst,
#lblCategoryCopy .siblingLinks,
.topheadbox .siblingLinks {
    scrollbar-color: var(--site-accent) var(--site-dark-grey);
}

/* menu */
h3.postTitle {
    padding-top: 15px;
}
