/* PDP Overrides */
@keyframes loading-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) {
    .sdDetails .AltProdDet .colmask {
        padding: 0;
    }
}

.easyzoom.easyzoom--overlay::after,
.swiper-container-thumbs .swiper-slide::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.13333);
    opacity: 0.3;
    pointer-events: none;
    transition: 0.3s ease-in-out;
}

.easyzoom.easyzoom--overlay:hover::after,
.swiper-container-thumbs .swiper-slide:hover::after {
    opacity: 0.65;
}

#productImages #productImageContainer .productImage.productImageGrid .innerImageContainer {
    gap: 10px 0;

    a .imgdot-zoom {
        background-color: transparent;
    }
}

#contentWrapper #mainDetails {
    margin: 0;

    @media (max-width: 767px) {
        .swiper-container-thumbs {
            padding: 0 15px;
        }

        .productImageContentWrapper > div:not(#pdpSashContainer, .promo-header-pdp, #productImages) {
            margin: 0 15px;
        }
    }
}

.sdDetails .productImageContentWrapper {
    display: grid;
    grid-template-columns: 60% 1fr;
    column-gap: 24px;
    grid-template-areas:
        "imageBlock sashContainer"
        "imageBlock promoContentTop"
        "imageBlock detailsBlock"
        "descriptionBlock detailsBlock";

    #pdpSashContainer .product-sash {
        display: block;
        position: static;
        font-size: 9px;
        font-weight: var(--font-weight-bold);
        padding: 5px;
        text-transform: uppercase;
        text-align: center;
    }

    @media (min-width: 1440px) {
        grid-template-columns: 70% 1fr;
    }

    @media (min-width: 1022px) {
        column-gap: 40px;
    }

    @media (min-width: 768px) {
        grid-template-rows: max-content max-content max-content 1fr;
    }

    #pdpSashContainer {
        position: relative;
        grid-area: sashContainer;

        @media (min-width: 768px) {
            margin: 0;
        }

        .sash-container {
            width: 100%;
            flex-direction: row;
            margin: 0;

            .product-sash {
                flex: 1 1 auto;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .promo-header-pdp {
        grid-area: promoContentTop;
        width: 100%;
        padding: 0;

        > div {
            margin-bottom: 15px;
        }
    }

    #productImages {
        position: relative;
        grid-area: imageBlock;
        margin: 19px 0 20px;

        @media (min-width: 768px) {
            margin: 0 0 45px;
        }

        #ImagesLoadingPlaceHolderWrap::before {
            display: none;
        }

        &.ImageNotReady .ThumbProdWrap,
        .ThumbProdWrap {
            position: relative;
            z-index: 1;
            opacity: 1;
        }

        &.ImageNotReady .ThumbProdWrap #productImageContainer::before,
        &.ImageNotReady .ThumbProdWrap #productImageContainer::after {
            content: "";
            display: block;
            position: absolute;
        }

        &.ImageNotReady .ThumbProdWrap #productImageContainer::before {
            background-color: var(--site-white);
            opacity: 0.8;
            block-size: 100%;
            inline-size: 100%;
            z-index: 901;
            inset-block-start: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-end: 0;
        }

        &.ImageNotReady .ThumbProdWrap #productImageContainer .swiper-slide {
            @media (min-width: 768px) {
                width: calc(50% - 9px);
                margin-right: 18px;
            }
        }

        &.ImageNotReady .ThumbProdWrap #productImageContainer::after {
            border-radius: 50%;
            block-size: 48px;
            inline-size: 48px;
            z-index: 902;
            inset-inline-start: 50%;
            inset-block-start: 50%;
            translate: -50% -50%;
            border-width: 3px;
            border-style: solid;
            border-color: var(--site-accent) var(--site-accent) transparent;
            animation: 1s loading-spinner linear infinite;
        }

        &.ImageNotReady .ThumbProdWrap #productThumbnails .swiper-slide {
            width: calc(18.1818% - 3px);
            margin-right: 4px;

            @media (min-width: 768px) {
                width: calc(16.6666% - 15px);
                margin-right: 18px;
            }
        }
    }

    #productDetails {
        grid-area: detailsBlock;
        padding: 0;

        @media (min-width: 768px) {
            margin-top: 34px;
        }
    }

    .productDescriptionWrapper {
        grid-area: descriptionBlock;
        order: 3;

        .promo-footer-pdp {
            margin-top: 20px;

            @media (min-width: 768px) {
                margin-top: 40px;
            }
        }
    }
}

.sdDetails .AltProdDet .title .brandTitle .brandLink span {
    font-weight: var(--font-weight-bold);
}

.sticky-atb-wrapper.stickyCTA-active {
    top: 123px;
}

/* PDP Digital Product Styling */
.digitalProduct .productNextDayDeliveryCountdownWrapper {
    display: none;
}

.productSampleGwpContainer,
.hasDropshipping,
.ProdDetails .pegiRating {
    padding: 0;
}

.sdDetails .gwpPromotionContainer {
    margin: 20px 0;
}

@media (max-width: 767px) {
    .sdDetails .dropShipSupplierInfoSummary {
        margin: 10px 0 15px;
    }

    .frasers-plus-breakdown {
        padding: 0 0 28px;
    }
}

#SocialModal {
    .modal-header {
        border-bottom: 1px solid var(--site-mid-grey);
    }
    .modal-body {
        height: auto;
        max-height: fit-content;
        padding: 0;
        background-color: transparent;
    }
}

#SocialModal .modal-content .close {
    background-image: var(--sitewide-close-svg) !important;
    background-position: center center !important;
    background-size: contain !important;
    width: 15px;
    right: 20px;
}

#divShareBoxContent {
    #shareCopyURLbutton {
        display: flex;
        gap: 10px;
        align-items: center;
        background-color: transparent !important;
        color: var(--site-black) !important;
        border: 0;
        padding: 15px;
        width: 100%;
        text-align: left;
        font-size: 14px;
        border-bottom: 1px solid var(--site-mid-grey);

        &:hover {
            text-decoration: underline;
        }

        .global-icon-copy svg {
            height: auto;
            width: 25px;
        }
    }

    .SocialLikes {
        width: 100%;
        padding: 0;
        margin: 0;

        .SocialLikesRow {
            display: flex;
            flex-direction: column;
            margin: 0;

            .SocialLike {
                display: flex;
                align-items: center;
                min-height: 45px;
                width: 100%;
                height: auto;
                border-bottom: 1px solid var(--site-mid-grey);

                .shareBy {
                    width: 100%;
                }

                .shareBy a {
                    display: flex;
                    gap: 10px;
                    padding: 15px;
                    font-size: 14px;
                    align-items: center;

                    &:hover {
                        text-decoration: underline;
                    }

                    .global-icon {
                        width: 25px;
                        height: auto;

                        svg {
                            max-height: 25px;
                        }
                    }
                }
            }
        }
    }
}

.ProdDetails.sdDetails .member-price {
    justify-content: flex-start;
    font-size: calc(var(--body-font-size) + 1px);
    text-transform: none;

    .member-price-label {
        font-weight: var(--font-weight-bold);
    }
}

.s-basket-quantity-text-box input::-webkit-outer-spin-button,
.s-basket-quantity-text-box input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.sdDetails .AltProdDet .pdpPriceRating .pdpPrice #lblSellingPrice {
    font-size: calc(var(--body-font-size) + 1px);
}

.sdDetails .WishListContain a {
    padding: 0;
}

.sdDetails .AltProdDet #productDetails .WishListContain .sAddToWishListWrapper {
    a svg path {
        stroke-width: 1.5;
        stroke: var(--site-dark-grey);
    }

    a:hover svg path {
        stroke: var(--site-dark-grey);
        fill: transparent;
    }

    a.addedWishList svg path {
        stroke: var(--site-dark-grey);
        fill: var(--site-dark-grey);
    }

    a.addedWishList:hover svg path {
        stroke: var(--site-dark-grey);
        fill: var(--site-dark-grey);
    }
}

.sdDetails .AltProdDet #productDetails .shareInner {
    .global-icon-share {
        svg path {
            stroke: var(--site-dark-grey);
        }

        &:hover svg path {
            stroke: var(--site-black);
        }
    }
}

.sdDetails #availableFromContainer .availableFromTitle {
    font-size: var(--body-font-size);
    font-weight: var(--font-weight-regular);
}

.sdDetails #availableFromContainer #litAvailableFromDateFormatted {
    font-size: var(--body-font-size);
    font-weight: var(--font-weight-bold);
}

.sdDetails #availableFromContainer .daysLeftCopy {
    font-size: var(--body-font-size);
}

.AltProdDet #productDetails {
    .addtoBagWrap .addToBasketContainer a,
    #divMultiplePurchases .qtyBorder,
    .BasketWishContainer .addtoBagWrap .sizeSelected a::after,
    .addtoBagWrap .addToBasketContainer.addedToBag a::after,
    .addtoBagWrap .addToBasketContainer.addToBagInProgress a::after {
        border-radius: var(--site-cta-border-radius);
    }

    .addtoBagWrap .addToBasketContainer {
        &.addToBagInProgress a,
        &.addedToBag a {
            background-color: var(--site-cta-background) !important;

            &:hover {
                background-color: var(--site-cta-background) !important;

                &,
                span {
                    color: var(--site-cta-text) !important;
                }
            }

            &:not(.addedToBag a)::after {
                background-color: var(--site-lightest-grey) !important;
                opacity: 0.4;
            }
        }

        &.addedToBag a::before {
            height: 13px;
        }
    }

    .productDetailsTop {
        display: flex;

        .titleAndPrice {
            flex: 1 1 auto;
        }

        .wishlistAndShare {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    .title .prodTitle #lblProductName {
        line-height: 1.3em;
    }

    .addtoBagWrap .addToBasketContainer a {
        padding: 13px 15px;
        border: 1px solid var(--site-black);
    }

    #aAddToWishList span.global-icon.global-icon-wishlist {
        background: none;
        padding: 12px 10px;
        border-radius: 50%;
    }

    #divShareButton {
        cursor: pointer;
        width: auto;
        text-align: center;
        float: none;
        font-size: 1em;
        padding: 0;

        .shareInner {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    #divPopupSizeGuide .sizeslink .SizeGuideText {
        text-decoration: underline;
    }
}

.productDetailsTop .pdpPriceRating {
    display: flex;
    align-items: center;
    gap: 5px;
}

#sticky-atb-wrapper .sticky-atb .sticky-atb--buysection {
    .sizeSelected a.addToBag,
    a.addToBag,
    a::after {
        border-radius: var(--site-cta-border-radius);
    }
}

.sdDetails .pdpMoreLinksDelivery {
    max-width: var(--large-container-max-width);
    padding: 0;
    margin: auto;
    border: 0;

    @media (min-width: 768px) {
        padding: calc(var(--padding-unit) * 2.5) 0 0;
    }
}

.infoaccordions {
    margin: 0;
    border-bottom: 1px solid var(--site-mid-grey);

    & > div {
        &,
        &:first-child {
            border: 0;
        }

        &:first-child .panel-heading,
        .panel-heading {
            border-top: 1px solid var(--site-mid-grey);

            & + div {
                border-top: 1px solid var(--site-mid-grey);

                &::before {
                    content: "";
                    display: block;
                    height: 15px;
                }
            }
        }

        .panel-body .productCode {
            display: block;
        }
    }

    #DisplayAttributes {
        max-width: 100%;

        li {
            border-top: 1px solid var(--site-light-grey);
            padding: 6px 0;

            &:first-child {
                border-top: 0;
            }

            .feature-name {
                flex: 1;
                font-weight: var(--font-weight-regular);

                &::after {
                    display: none;
                }
            }
        }
    }

    .DeliveryMethods li {
        display: block;
    }

    .productDescriptionInfoText > div > span,
    .productDescriptionInfoText > div > strong {
        display: block;
        padding-top: 30px;
    }

    h4::after {
        background-image: var(--sitewide-minus-svg) !important;
        background-size: contain !important;
        background-position: center !important;
        width: 14px !important;
    }

    a.collapsed h4::after {
        background-image: var(--sitewide-plus-svg) !important;
        width: 14px;
    }
}

@media (max-width: 767px) {
    .sdDetails .ContentWrapper .productDescriptionWrapper {
        padding: calc(var(--padding-unit) * 2.5) 0 0;
    }
}

.sdDetails #MoreFromLinks {
    display: flex;
    justify-content: center;
    border: none;

    @media (min-width: 768px) {
        padding: 0;
    }

    ul {
        display: inline-flex;
        flex-wrap: nowrap;
        gap: 5px 15px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: var(--site-mid-grey);
            border-radius: 4px;
            margin-left: 16px;
            margin-right: 16px;
        }

        &::-webkit-scrollbar-track-piece {
            background-color: var(--site-mid-grey);
        }

        &::-webkit-scrollbar-thumb {
            background: var(--site-black);
            border-radius: 4px;
        }
    }

    li.MoreFromLinksRow a {
        padding: 10px 25px;
        border: 1px solid var(--site-mid-grey);
        border-radius: var(--site-cta-border-radius);
        text-decoration: none;
        white-space: nowrap;

        &:hover {
            border-color: var(--site-black);
        }
    }
}

.sdDetails .sizeButtons li a:hover,
.sdDetails .colourImages li a:hover,
.sdDetails .sizeButtons li a:focus {
    border: 1px solid var(--site-black);
}

.SzQuantGroup .sizeButtons li a,
.sdDetails .colourImages li.greyOut a,
#hotspotModal .PinWrapText .hsVariantButtons li a {
    border-radius: var(--site-cta-border-radius);
}

.SzQuantGroup .sizeButtons li.sizeVariantHighlight a {
    border-radius: var(--site-cta-border-radius);
    border: 1px solid var(--site-black);
    color: var(--site-black);
}

.sdDetails .frasers-plus-payment-enabled .FrasersPlusContainer {
    padding: 0;
}

.sdDetails .colourImages {
    li a {
        border-radius: var(--site-cta-border-radius);
        padding: 0;

        &:hover {
            border: 1px solid var(--site-black);
        }

        img {
            padding: 0;
        }
    }

    li.variantHighlight a {
        border: 1px solid var(--site-black);

        &:hover {
            border: 1px solid var(--site-black);
        }
    }
}

/* pdp native recently viewed */

#pnlRecentlyViewedProducts .AltStratSellPrice {
    color: var(--site-red);
}

#pnlRecentlyViewedProducts .AltStratProduct .AltStratRefPrice {
    font-weight: var(--font-weight-regular);
}

.AltVertOne .AltStratProduct .AltStratBrand,
.AltVertOne .AltStratProduct .AltStratDescription {
    font-size: var(--body-font-size);
    line-height: var(--font-line-height);
    font-weight: var(--font-weight-regular);
}

#pnlRecentlyViewedProducts h4 {
    grid-area: RVproductName;
}

.AltStratRefSell {
    grid-area: RVproductPrice;
}

.RvStratImgQuick {
    grid-area: RVImage;
}

.wishlist-quickbuy-wrapper {
    grid-area: RVquickArea;
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding-top: 5px;

    @media (min-width: 1021px) {
        flex-direction: column;
    }
}

.AltStratProduct {
    display: grid !important;
    grid-template-columns: 1fr 40px;
    grid-template-rows: 1fr;
    column-gap: 5px;
    grid-template-areas:
        "RVImage RVImage"
        "RVproductName RVquickArea"
        "frasersPlus RVquickArea"
        "RVproductPrice RVquickArea"
        "RVreviews RVquickArea";

    @media (max-width: 1021px) {
        display: block;
        grid-template-areas:
            "RVImage"
            "RVproductName"
            "frasersPlus "
            "RVproductPrice"
            "RVreviews"
            "RVquickArea";
        grid-template-columns: 1fr;
    }
}

#pnlRecentlyViewedProducts .AltVertOne .AltStratProdImg::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.13333);
    opacity: 0.3;
    pointer-events: none;
    transition: 0.3s ease-in-out;
}

#pnlRecentlyViewedProducts .AltVertOne .AltStratProdImg:hover::after {
    opacity: 0.65;
}

.recently-viewed-swiper-container .swiper-scrollbar {
    z-index: 1;
}

.sdDetails .hotspotbuy.hotspotwishlist,
.sdDetails .hotspotbuy.hotspotquickbuy {
    position: static;
    display: block !important;
    width: 40px;
    height: 40px;
    background-color: var(--site-lightest-grey);
    border-radius: 50%;
}

.hotspotwishlist .WishIcon svg,
.hotspotquickbuy .QuickLookIcon svg {
    width: 24px;
    height: 24px;

    path {
        stroke: var(--site-dark-grey);
    }
}

.hotspotwishlist:hover .WishIcon svg path,
.hotspotquickbuy:hover .QuickLookIcon svg path {
    stroke: var(--site-black);
}

.wishlist-quickbuy-wrapper .hotspotwishlist .WishIcon,
.wishlist-quickbuy-wrapper .hotspotquickbuy .QuickLookIcon {
    padding: 8px;
    display: block;
    width: 40px;
    height: 40px;
    background-color: var(--site-lightest-grey);
    background: none;
}

#pnlRecentlyViewedProducts .swiper-slide {
    padding-right: calc(var(--padding-unit) * 4);
}

.AltStratTitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AltVertOne #lblHeaderText {
    font-weight: var(--font-weight-bold);
    font-size: 22px;
}

.rv-swiper-button-next,
.rv-swiper-button-prev {
    display: none;
    top: 42%;
    z-index: 5;
    @media (max-width: 1220px) {
        top: 30%;
    }
    @media (min-width: 768px) {
        display: block;
    }
}

.rv-swiper-button-next:after,
.rv-swiper-button-prev:after {
    display: none;
}

.rv-swiper-button-next:not(.swiper-button-disabled),
.rv-swiper-button-prev:not(.swiper-button-disabled),
#productImages #productImageContainer .productTwoImageCarousel .swiper-container-thumbs .pdp-carousel-next,
#productImages #productImageContainer .productTwoImageCarousel .swiper-container-thumbs .pdp-carousel-prev {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--pdp-two-image-carousel-thumbnail-border-radius);
    background-color: var(--site-white);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
        display: none;
    }
}

#productImages #productImageContainer .productTwoImageCarousel .swiper-container-thumbs {
    --swiper-navigation-size: 32px;

    .pdp-carousel-next,
    .pdp-carousel-prev {
        width: 32px;
        height: 32px;
    }
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    display: none;
}

.AltStratProduct .reviews-container {
    grid-area: RVreviews;
    padding: 5px 5px 0;
    display: flex;
    text-align: left;
}

.swiper-scrollbar.swiper-scrollbar-horizontal.rv-swiper-scrollbar {
    bottom: -40px;
    max-width: 50%;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
}

.sdDetails #pnlRecentlyViewedProducts {
    padding: 0px 0px 40px;
}

@media (min-width: 768px) {
    .sdDetails #pnlRecentlyViewedProducts {
        padding: 20px 60px 40px;
    }
}

.rv-swiper-scrollbar .swiper-scrollbar-drag {
    background-color: var(--site-black);
}

.global-icon.global-icon-arrow svg path {
    fill: var(--site-black);
}

.swiper-button-prev.rv-swiper-button-prev .global-icon-arrow svg,
.pdp-carousel-prev .global-icon-arrow svg {
    rotate: 180deg;
}

.global-icon-arrow svg {
    width: 20px;

    .swiper-container-thumbs & {
        width: 15px;
    }
}

.AltProdDet #productDetails .personalisationContainer {
    margin-top: 0px;
}
