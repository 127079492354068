/* PLP Overrides */

.NextLink.disablePaginationArrow,
.PrevLink.disablePaginationArrow {
    pointer-events: none;
    cursor: default;
    opacity: 0.2;
}

#showFilterButtonDiv .btn.btn-default.showFilterButton {
    display: block;
    background-color: var(--site-cta-background);
    color: var(--site-cta-text);
    border-radius: var(--site-cta-border-radius);
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }

    &:hover {
        background-color: var(--site-cta-background-hover);
        color: var(--site-cta-text-hover);
    }
}

.Browse .s-productscontainer2 {
    .s-productthumbimage .ProductImageList img,
    .TextSizeWrap {
        padding: 0;
    }
}

#productlistcontainer .productimage.s-productthumbimage:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.13333);
    opacity: 0.3;
    pointer-events: none;
    transition: 0.3s ease-in-out;
}

#productlistcontainer .s-productthumbbox:hover .productimage.s-productthumbimage:after {
    opacity: 0.65;
}

.s-productthumbbox .sashContainer {
    height: var(--site-plp-sash-height);
}

[data-sash-touchpoint="PLP"] {
    .product-sash {
        flex: 1 1 auto;
        padding: 5px;
        font-size: 9px;
        font-weight: var(--font-weight-bold);
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media (max-width: 1639px) {
        width: fit-content;

        .product-sash {
            display: flex;
            flex: 0 0 100%;
            width: auto;
            position: absolute;
        }

        .product-sash:nth-of-type(1) {
            animation-delay: 0s;
        }

        .product-sash:nth-of-type(2) {
            animation-delay: 4s !important;
        }

        .product-sash:first-child:nth-last-child(1),
        .product-sash:first-child:nth-last-child(1) ~ .product-sash {
            animation: 1s autoplay1sash infinite ease;
        }

        @keyframes autoplay1sash {
            0% {
                opacity: 1;
                z-index: 1;
            }

            100% {
                opacity: 1;
                z-index: 1;
            }
        }

        .product-sash:first-child:nth-last-child(2),
        .product-sash:first-child:nth-last-child(2) ~ .product-sash {
            animation: 8s autoplay2sashes infinite ease;
        }

        @keyframes autoplay2sashes {
            0% {
                opacity: 0;
                z-index: -1;
            }

            2% {
                opacity: 1;
                z-index: 1;
            }

            50% {
                opacity: 1;
                z-index: 1;
            }

            52% {
                opacity: 0;
                z-index: -1;
            }

            100% {
                opacity: 0;
                z-index: -1;
            }
        }
    }
}

.Browse .s-productscontainer2 {
    [data-sash-touchpoint="PLP"] .product-sash {
        z-index: 1;
    }

    &.plp-products-container li:hover .s-productthumbbox {
        box-shadow: none;
    }

    .TextSizeWrap .s-productthumbtext {
        display: grid !important;
        grid-template-columns: 1fr 40px;
        grid-template-rows: 1fr;
        column-gap: 5px;
        grid-template-areas:
            "productName quickArea"
            "productAttributes quickArea"
            "frasersPlus quickArea"
            "productPrice quickArea";

        @media (max-width: 767px) {
            display: block;
            grid-template-columns: 1fr;
            grid-template-areas:
                "productName"
                "productAttributes"
                "frasersPlus "
                "productPrice"
                "quickArea";
        }

        .s-producttext-top-wrapper {
            grid-area: productName;

            .productdescriptionbrand {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: var(--body-font-size);
                font-weight: var(--font-weight-bold);
                line-height: 18px;
            }

            .productdescriptionname {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
                font-size: calc(var(--body-font-size) + 1px);
                line-height: 18px;
            }
        }

        .s-productthumb-extra {
            grid-area: productAttributes;
        }

        .member-pricing {
            grid-area: frasersPlus;
            font-size: calc(var(--body-font-size) + 1px);

            p {
                font-size: 1em;
                font-weight: var(--font-weight-bold);
                text-transform: none;
            }
        }

        .s-producttext-price {
            grid-area: productPrice;

            .s-largered .curprice {
                font-size: calc(var(--body-font-size) + 1px);
            }
        }

        .wishlist-quickbuy-wrapper {
            grid-area: quickArea;
            padding-top: 12px;
        }
    }

    .wishlist-quickbuy-wrapper {
        display: flex;
        flex-direction: column;
        gap: 11px;
        position: absolute;
        z-index: 1;
        right: 5px;

        .hotspotbuy.hotspotquickbuy,
        .hotspotbuy.hotspotwishlist {
            position: static;
            display: block !important;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-color: var(--site-white);
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }

        .hotspotbuy.hotspotwishlist {
            .wishListSVG {
                padding: 8px;
                width: 36px;
                height: 36px;

                svg {
                    fill: none;
                    width: 18px;
                    left: calc(50% - 1px);

                    path {
                        stroke: var(--site-dark-grey);
                    }
                }

                &:hover svg path {
                    stroke: var(--site-black);
                }
            }

            &.addedWishList .wishListSVG .global-icon svg path {
                stroke: var(--site-black);
                fill: var(--site-black);
            }

            &.addedWishList .wishListSVG .global-icon svg .icon-hover {
                fill: var(--site-white);
            }
        }

        .hotspotbuy.hotspotquickbuy {
            .QuickLookIcon {
                width: 35px;
                height: 33px;
                background-color: transparent;

                &:hover svg path,
                &:hover svg polygon {
                    stroke: var(--site-black);
                }
            }

            .QuickLookIcon svg {
                width: 16px;
                left: calc(50% - 1px);
                fill: none;

                path,
                polygon {
                    stroke: var(--site-dark-grey);
                }
            }
        }
    }
}

.Browse .s-productscontainer2:not(.swiper-wrapper) {
    row-gap: calc(var(--padding-unit) * 12);
    column-gap: calc(var(--padding-unit) * 4);
}

.Browse.sdlProdList #lblCategoryCopy {
    padding: 0;
    margin: 0;

    @media (max-width: 767px) {
        margin-top: 8px;
    }

    .catTemplate:not(.ImgCatVersion) {
        .catDesc {
            padding: 0 40px;

            @media (min-width: 1640px) {
                padding: 0;
            }

            @media (max-width: 1021px) {
                padding: 0 15px;
            }
        }

        @media (min-width: 1022px) {
            justify-content: flex-start;
        }

        .siblingLinks {
            span a {
                border-radius: var(--padding-unit);
            }
        }
    }

    .catTemplate .siblingLinks,
    .topheadbox .siblingLinks {
        padding: calc(var(--padding-unit) * 4.5) 40px 0;

        @media (max-width: 1021px) {
            padding: calc(var(--padding-unit) * 4.5) 15px 0;
        }

        a {
            text-transform: none;
        }

        span:first-of-type {
            margin-left: 0;
        }
    }
}

#hotspotModal .PinWrapText {
    .hsProductName {
        order: 0;

        h2 {
            color: var(--site-black);
            font-weight: var(--font-weight-bold);

            span {
                font-weight: var(--font-weight-regular);
            }
        }
    }

    .member-price {
        font-size: calc(var(--body-font-size) + 1px);

        &[data-member-price-scheme="FRAS"] {
            padding: 0;
        }
    }

    #hsPriceWrapper #hsRefPrice {
        font-weight: var(--font-weight-regular);
    }

    .hsVariantButtons .hsSizeButtonli {
        & a {
            font-size: var(--body-font-size);
        }

        & a,
        & a:hover,
        &.hsVariantHighlight a,
        &.hsVariantHighlight a:hover {
            font-weight: var(--font-weight-regular);
        }

        & a:hover {
            border-color: var(--site-black);
        }

        &.hsVariantHighlight a {
            border-color: var(--site-black) !important;
            color: var(--site-black);
        }
    }

    .hsColourDesc .ColourLabel,
    .hsSizeLabel {
        font-weight: var(--font-weight-bold);
    }
}

.sdDetails #productDetails .productVariantContainer #availableFromContainer {
    background-color: #000;
    color: #fff;
    padding: 16px 0;
    text-align: center;
    border-radius: var(--padding-unit);
}

#hotspotModal {
    .modal-header {
        .close {
            background-image: var(--sitewide-close-svg) !important;
            background-size: contain !important;
            background-position: center center !important;
            height: 18px;
            width: 18px;
            right: 20px;
            top: 16px;
        }
    }

    .PinWrapText {
        #hsViewProduct a {
            font-size: var(--subtitle-font-size);
            border-radius: 0;
        }

        #hsAddToBagContainer {
            a {
                padding: 13px;
            }

            a,
            a:hover {
                border: 1px solid var(--site-cta-background);
            }
        }

        #hsAddToWishListContainer {
            a,
            a:hover {
                border: 1px solid var(--site-cta-background);
            }
        }
    }
}

.flexFiltersContainer .mobSortFilter .MobSortSelector:has(.productFilterTitleBox) {
    input[type="radio"] {
        display: grid;
        place-content: center;
        -webkit-appearance: none;
        appearance: none;
        background-color: var(--site-white);
        margin: 0;
        font: inherit;
        color: var(--site-black);
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid var(--site-black);
        border-radius: 50%;

        &::before {
            content: "";
            width: 0.6em;
            height: 0.6em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--site-black);
        }

        &:checked::before {
            transform: scale(1);
        }
    }

    .FilterAnchor {
        gap: 5px;
    }
}

#PriceFilterTextEntry input,
#divBrandSearch input {
    border-radius: var(--border-radius);
}

.flexFiltersContainer .mobSortFilter .MobSortSelector:has(.productFilterTitleBox:not(.FilterClosed)) {
    border-color: var(--site-black);
    border-bottom-color: var(--site-white);
    border-radius: var(--site-cta-border-radius);

    .productFilterList {
        border-color: var(--site-black);
        border-radius: var(--site-cta-border-radius);
    }
}

.flexFiltersContainer .mobSortFilter #filterByMob,
.flexFiltersContainer .mobSortFilter .MobSortSelector {
    border-radius: var(--site-cta-border-radius);
}

#FiltersHeader .mobappfltrsNo.activeFilters,
#filterByMob.activeFilters .mobappfltrsNo {
    color: var(--site-black);
}

#Body.Browse #mobFilterControls .clearFiltersOpen .textIconWrap {
    background-color: var(--site-cta-background) !important;
    color: var(--site-cta-text);
    border-radius: var(--site-cta-border-radius);
    border: var(--site-cta-border);

    &:hover {
        background-color: var(--site-cta-background-hover);
        color: var(--site-cta-text-hover);
    }
}

.Responsive .mobDdClose .clsFilterIcon::before {
    background-image: var(--sitewide-close-svg);
    background-size: contain;
    background-position: center center;
    width: 18px;
    background-repeat: no-repeat;
}

.Browse #innerfiltercontainer .productFilterTitleBox .glyphicon::before {
    background-image: var(--sitewide-minus-svg);
    background-size: contain;
    background-position: center center;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    transform: none !important;
}

.Browse #innerfiltercontainer .productFilterTitleBox.FilterClosed .glyphicon::before {
    background-image: var(--sitewide-plus-svg);
    background-size: contain !important;
    background-position: center center !important;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    transform: none !important;
}

.Browse .productFilterList .SelectableFilter::before,
.Browse .productFilterList .SelectedFilter::before {
    border-radius: var(--border-radius);
}

.paginationWrapper .pagination .PageNumber a.PrevLink::before,
.paginationWrapper .pagination .PageNumber a.NextLink::before {
    background-image: var(--sitewide-chevron-svg);
    background-size: contain !important;
    background-position: center center !important;
    background-repeat: no-repeat;
    width: 15px;
    top: 7px;
}

.paginationWrapper .pagination .PageNumber a.PrevLink::before {
    transform: none;
    right: 0;
}

.paginationWrapper .pagination .PageNumber a.NextLink::before {
    transform: rotate(180deg);
    left: 0;
}

#PriceFilterTextEntry #PriceFilterTextEntryApply {
    background-color: var(--site-accent);
    color: var(--site-white);
    border: none;

    &:hover {
        background-color: var(--site-accent-secondary);
    }
}

#Body.Browse #mobFilterControls #mobappfltrs.mobFilterDisabled .textIconWrap {
    background-color: var(--site-cta-disabled-background) !important;
    border-color: var(--site-mid-grey);

    span {
        color: var(--site-cta-disabled-text);
        font-weight: var(--font-weight-bold);
    }
}

#Body.Browse #mobFilterControls .textIconWrap span {
    font-weight: var(--font-weight-bold);
}

/* PLP Overrides - Filter Toggle */
#BodyWrap .FilterProductsTable {
    @media (min-width: 1022px) {
        display: grid;
        grid-template-columns: 220px 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "productFilters productList";
        transition: grid-template-columns 100ms ease-in-out;

        #FilterContainer {
            grid-area: productFilters;
            transition: transform 100ms ease-in-out;

            #innerfiltercontainer {
                width: 100% !important;

                .productFilters .productFilter.sort-by-in-filters input[type="radio"],
                .productFilterList .FilterName {
                    cursor: pointer;
                }

                .productFilters .productFilter.sort-by-in-filters input[type="radio"] {
                    &::before {
                        background-color: var(--site-black);
                    }

                    &:checked {
                        border-color: var(--site-black);
                    }
                }
            }
        }

        #ProductContainer {
            grid-area: productList;
            background-color: var(--site-white);
        }

        .filtersHidden& {
            grid-template-columns: 0 1fr;

            #FilterContainer {
                transform: translateX(220px);
            }

            #ProductContainer {
                padding-left: 0;
            }
        }
    }
}

.Browse .productFilterList .SelectableFilter:hover::before,
.Browse #innerfiltercontainer .productFilters .productFilter.sort-by-in-filters input[type="radio"]:hover {
    border: 1px solid var(--site-black);
    outline: none;
}
.Browse .productFilterList .SelectableFilter:active::before,
.Browse #innerfiltercontainer .productFilters .productFilter.sort-by-in-filters input[type="radio"]:active {
    outline: 1px solid var(--site-black);
    outline-offset: 1px;
}

.Browse .productFilterList .FilterValue {
    color: var(--site-black);
}

.Browse .productFilterList .SelectedFilter::before {
    background-color: var(--site-black);
}

.Browse #innerfiltercontainer .productFilters .productFilter.sort-by-in-filters input[type="radio"]:hover,
.Browse .productFilterList .SelectableFilter:hover::before {
    border: 1px solid var(--site-black);
}

.Browse #innerfiltercontainer .productFilters .productFilter.sort-by-in-filters input[type="radio"]:active,
.Browse .productFilterList .SelectableFilter:active::before {
    outline: 1px solid var(--site-black);
}

.sdlProdList #spnSearchIcon .glyphicon {
    background-image: var(--sitewide-search-svg) !important;
    background-size: contain !important;
    background-position: center center !important;
    height: 25px;
    width: 25px;
}

.sort-by-in-filters,
.FilterListItem {
    padding-left: 2px;
}

.Browse #FilterContainer #innerfiltercontainer {
    padding: 0;
}

.Browse #FilterContainer #mobFilterControls {
    padding: 20px !important;
}

.PageFromTo {
    border-bottom: 1px solid #ddd;
}

.Browse .s-productscontainer2 .TextSizeWrap .s-productthumbtext .s-producttext-top-wrapper .productdescriptionname {
    display: block;
    white-space: nowrap;
}

.sdlProdList .categorycopyd4 #lblCategoryCopy {
    font-size: 15px;
    padding: 0 16px;

    @media (min-width: 1022px) {
        padding: 0 40px;
    }

    @media (min-width: 1640px) {
        padding: 0;
    }
}

.flexFiltersContainer .mobSortFilter {
    flex-basis: 50%;

    @media (min-width: 768px) and (max-width: 1021px) {
        flex-basis: 25%;
    }
}

.mobFilterAppIcon {
    height: 22px;
    width: 22px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4834_39361)'%3E%3Crect x='0.5' y='12.8455' width='20' height='1.40261' rx='0.701304' fill='black'/%3E%3Cellipse cx='5.83715' cy='13.5468' rx='2.02465' ry='1.86407' fill='black'/%3E%3Crect x='20.5' y='7.97632' width='20' height='1.40261' rx='0.701305' transform='rotate(-180 20.5 7.97632)' fill='black'/%3E%3Cellipse cx='15.1628' cy='7.27497' rx='2.02465' ry='1.86407' transform='rotate(-180 15.1628 7.27497)' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4834_39361'%3E%3Crect width='20' height='20' fill='white' transform='translate(0.5 0.190918)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3e");
    background-position: center center;
    background-size: contain;
}

.MobSortSelector .productFilterTitleBox .glyphicon::before {
    background-image: var(--sitewide-chevron-svg) !important;
    background-size: cover;
    background-position: center center;
}

.MobSortSelector .productFilterTitleBox.FilterClosed .glyphicon::before {
    transform: rotate(-90deg);
    transition: transform 0.3s ease-in-out;
}

.productFilterTitleBox .glyphicon {
    float: right;
    height: 13px;
    font-size: 1.2em;
    text-align: right;
    width: 13px;
    top: 0px;
}

.productFilterTitleBox .glyphicon::before,
.productFilterTitleBox.FilterClosed .glyphicon::before {
    height: 13px;
    width: 13px;
}

.flexFiltersContainer .mobSortFilter .MobSortSelector:has(.productFilterTitleBox:not(.FilterClosed)) {
    border-radius: 4px 4px 0 0;
}

.productFilterTitleBox .FilterCollapseImage:before {
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}

#BodyWrap .PageNumber span.PageSelector {
    color: var(--site-black);
    font-weight: var(--font-weight-bold);
}

.swipePrevClick .PrevLink .disablePaginationArrow::before {
    color: var(--site-light-grey);
}

.sdlProdList.Browse #lblCategoryCopy {
    display: block !important;
}

.Browse .HeadnCount {
    padding: 10px 16px 0;
}

.s-breadcrumbs-bar a:first-of-type {
    padding: 0;
}

.paginationWrapper .pagination .PageNumber a.PrevLink {
    margin-right: 2px;
}

.paginationWrapper .pagination .PageNumber a.NextLink {
    margin-left: 2px;
}

.pagination .PageNumber {
    float: none;
    text-align: center;
}

.topheadbox .PageFromTo .pppt,
.topheadbox .PageFromTo .pppt span {
    display: none;
}

.Responsive .mobDdClose {
    bottom: 50px;
}

.mobile-read-more-less-enabled #lblCategoryCopy #read-more-less,
.mobile-read-more-less-enabled .topheadbox #read-more-less {
    position: static;
    text-align: left;
}

@media (max-width: 1021px) {
    .Browse #innerfiltercontainer .productFilters {
        padding: 0 15px;
    }

    .sdlProdList .ChildCategoriesListWrapper {
        padding: 0 20px;
    }

    #Body .s-productthumbbox .hotspotquickbuy {
        display: block !important;
    }

    .PageFromTo {
        border-bottom: none;
    }
}

@media (max-width: 767px) {
    .Browse .s-productscontainer2 .wishlist-quickbuy-wrapper {
        flex-direction: column;
    }

    #TopPaginationWrapper.sticky .paginationWrapper {
        top: 123px;
    }
}

@media (min-width: 1022px) {
    .HeadnCount .PageFromTo .pppt,
    .FiltersTitle {
        display: none;
    }

    #innerfiltercontainer #FiltersHeader {
        display: none;
    }
}

.gwpPromotionContainer .gwpPromotion {
    background-color: #fff;
    border: 0;
    border-top: 1px solid var(--site-mid-grey);

    div.promotionInfo {
        width: calc(100% - 120px);
        padding: 50px 5px 15px 10px;

        a {
            color: var(--site-black);
        }

        .promotionInfoTitle {
            position: absolute;
            top: 35px;
            left: 30px;
            width: auto;
            text-align: center;
            text-transform: capitalize;
            color: var(--site-cta-background);

            @media (min-width: 768px) {
                left: 15px;
            }

            &::before {
                background-image: url(https://www.flannels.com/images/core/flannels-20-sprite-sheet-v9.svg);
                background-repeat: no-repeat;
                content: "";
                display: inline-block;
                width: 25px;
                height: 25px;
                vertical-align: middle;
                background-repeat: no-repeat;
                background-position: -284px -230px;
                background-size: 405px 405px;
                margin-right: 5px;
            }
        }

        .promotionInfoDescription,
        .promotionInfoProduct {
            font-size: 14px;
        }

        .promotionInfoMore {
            display: none;
        }
    }

    img.promotionInfo {
        width: 100px;
        height: 100%;
        text-align: center;
        line-height: 70px;
        margin: 30px 5px 0;
        display: inline-block;
    }
}

.ProdDetails .pegiWrap {
    border-top: 1px solid var(--site-mid-grey);
}
