#Body,
p,
a,
div,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
input,
textarea,
html,
blockquote,
th,
td,
tr,
legend,
select,
button,
input[type="submit"],
input[type="button"],
.prc_comment_postdatetime,
#StoreFinderResultsAttributes ul li,
nav#topMenu li.root .SubMenuWrapper > ul li a {
    font-family: RobotoCondensed, Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
nav#topMenu > ul li a,
nav#topMenu li.root .SubMenuWrapper > ul li.MenuHeader > a,
nav#topMenu ul li.root .SubMenuWrapper ul li.Right .FeatureGraphic .singleAdCTA span {
    font-family: RobotoCondensed, Arial, Helvetica, sans-serif;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input[type="submit"],
button,
input[type="submit"],
input[type="button"],
#aPayNow,
#aViewBag,
.CheckWrap input.ContinueOn,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.Responsive .Basket #BasketDiv .UpdateQuant:hover,
.DeliveryOptions .SelectButton {
    background-color: var(--site-black) !important;
    background-image: none;
    color: var(--site-white) !important;
    border: 1px solid var(--site-black);
}

.ImgButWrap a:hover,
.ImgButWrap input[type="submit"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
.ImgButWrap a:focus,
button:hover,
#aPayNow:hover,
#aViewBag:hover,
.CheckWrap input.ContinueOn:hover,
.CheckWrap a.ContinueOn:hover,
.CheckWrap .ContinueOn a:hover,
.Responsive .Basket #BasketDiv .UpdateQuant,
.DeliveryOptions .SelectButton:hover {
    background-color: #666 !important;
}

#NewsLetterModal.modal .close:hover,
div.NewMenuWrap ul > li.level1:hover,
nav#topMenu > ul li.columnGroup {
    background-color: transparent !important;
}

.RefandPrice .s-smalltext {
    color: #666;
}

ins {
    background: #ff9;
}

mark {
    background: #ff0;
}

.mp-level,
#socEmailBarCont {
    background: #333;
}

.HeaderIcons li > a {
    background: none;
}

.menu-trigger {
    background-color: #000 !important;
}

a.dnnPrimaryAction:hover {
    background: #666;
}

nav#topMenu li.root .SubMenuWrapper > ul li.MenuShopAll > a,
.ToplinksGroup,
.footercardMenu,
a.dnnPrimaryAction,
.cruiseSocIcons,
.LogoCheck,
.fullforBackg,
.FooterWrap .connectContainer,
.FooterWrap .Copyright,
#topLinkMenu ul li.TopLinkDrop ul li.TopSubViewAll,
#divBagTotalLink a#aBagLink:hover,
nav#topMenu ul li.root .SubMenuWrapper ul li.ChildrenCenter.Center ul .columnGroup + li + li + li + li .kidsMainImage a,
nav#topMenu ul li.root .SubMenuWrapper ul.New.Arrivals li.Center .level1 a::before,
nav#topMenu ul li.root .SubMenuWrapper ul li.Cruise.LifeRight.Right .blogDropdown > div .postImage,
.HeaderWrap,
.TopNavBar {
    background-color: #000;
}

.FooterSubGroup,
.dbc,
.PSPlacementTitle,
.AltStratTitle,
.HeaderIcons > li a:hover,
#divBagTotalLink span#bagQuantity,
#topLinkMenu ul li a:hover,
.currencyLanguageSelector {
    background-color: transparent;
}

nav#topMenu ul li.root .SubMenuWrapper > ul .Left,
nav#topMenu ul li.root .SubMenuWrapper > ul div.Left::before,
nav#topMenu ul li.root .SubMenuWrapper > ul div.Left::after {
    background-color: #e7e7e7;
}

body,
nav#topMenu li.root .SubMenuWrapper,
nav#topMenu > ul li.columnGroup,
#divBagItems #divBagItemsChild,
#divAjaxLoaderImage,
#divEmptyErrorMessage,
#ulBag li,
#ulBag li img.Baskimg,
.BodyWrap,
#BodyWrap,
.RadTabStrip .rtsLevel .rtsOut,
.RadTabStrip .rtsLevel .rtsIn,
nav#topMenu ul li.root .SubMenuWrapper,
.spanCurrencyLanguageSelector::before,
#topLinkMenu ul.TopSubLinkMenu > li {
    background-color: #fff;
}

body,
h1,
h1 span,
h2,
h3,
h4,
h5,
h6,
p,
.outofstock,
.saleprice,
.PSSellPrice,
.SubBask .PriceTotalBask,
.DontForgetText,
a:hover,
ins,
mark,
.BaskPrice,
.BaskName:hover,
.Basket h1,
.Basket #BasketDiv table a,
.Basket #BasketDiv table a:hover,
.Basket #BasketDiv table td,
.Basket #BasketDiv .basketsubtotal,
.csHomeTable li.header a,
li.header a:hover,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a {
    color: #333;
}

nav#topMenu li.root .SubMenuWrapper > ul li a:hover,
.PSPlacementTitle,
.AltStratTitle,
#topLinkMenu ul.TopLinkMenu li a,
nav#topMenu ul li.root .SubMenuWrapper ul li.level1 > a,
nav#topMenu li.root .SubMenuWrapper > ul li.crusMenuFooter a,
nav#topMenu li.root .SubMenuWrapper > ul li .linkStrip a::before,
nav#topMenu li.root .SubMenuWrapper > ul li .linkStrip a::after,
.sdBasket #divBagTotalLink #bagQuantity,
.spanCurrencyLanguageSelector p,
.spanCurrencyLanguageSelector p:hover,
.accordionMenuContentWrap .menuitemtext,
.accordionMenuContentWrap #spanLanguageMenuItem,
.accordionMenuContentWrap #spanCurrencyMenuItem,
.accordionMenuContentWrap #spanSelectedCurrency,
.accordionMenuContentWrap .mob-login,
.s-productthumbtext .productdescriptionbrand,
nav#topMenu ul li.root .SubMenuWrapper .FeatureGraphic .singleAdCTA span {
    color: #000;
}

nav#topMenu li.root > ul li a,
nav#topMenu li.root .SubMenuWrapper > ul li.MenuShopAll a:hover,
#topLinkMenu ul li.TopLinkDrop ul li.TopSubViewAll a,
.signup-copy p,
.ResponsiveSwitch a.rs-link,
.HeaderIcons > li a:hover,
#divBagTotalLink a#aBagLink:hover,
#lblWishListCount,
#NewsLetterModal .form-wrapper label,
.mp-menu .MobileMenuContentWrap .shop ul li > a,
.csImageHeader h2,
.footerDesktop p,
.bxsliderFooter p,
.uscBottomSection .bx-wrapper .bx-viewport li p,
.CareersTopMenu li a {
    color: #fff;
}

.shop > ul > li > a {
    color: #333 !important;
}

a,
a:active,
a:focus,
a:link,
a:visited {
    color: #636363;
}

.dbc {
    color: #1e1e1e;
}

input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled,
input[type="file"]:disabled::-webkit-file-upload-button,
button:disabled,
select:disabled,
keygen:disabled,
optgroup:disabled,
option:disabled,
.BaskColr,
.BaskSize,
nav#topMenu li.root .SubMenuWrapper > ul li a,
.originalprice .originalTicket {
    color: #888;
}

nav#topMenu li.root .SubMenuWrapper > ul li.MenuShopAll > a,
nav#topMenu ul li.root > a,
#divBagTotalLink span,
#FootcmdSearch,
.socEmailBar,
#signupTxt,
#emailBarClose a,
.HeaderIcons li a,
.footercardMenu a,
.addressbook-options-buttons .dnnPrimaryAction,
.addressbook-options .dnnPrimaryAction,
#addressbook-delete-dialog-options .dnnPrimaryAction,
.Responsive .Basket #BasketDiv .UpdateQuant,
.FooterGroupLink li a,
.RegistrationButtons .RegistrationSubmit {
    color: #fff;
}

.maxText {
    color: #e81515;
}

.ToplinksGroup .search input.watermarked {
    color: #636363;
}

.CareersBrand .vacancy-search-submit a,
.CareersBrand td.vacancy-more-info-btn > a,
#divBrandSearch input {
    border-color: #333;
}

.CareersBrand .vacancy-search-submit a:hover,
.CareersBrand td.vacancy-more-info-btn > a:hover,
#NewsLetterModal .header-text {
    border-color: #666;
}

.CareersBrand li.topLevelLink {
    border-bottom: solid 1px #333;
}

.CareersBrand li.topLevelLink:hover {
    border-bottom: solid 1px #666;
}

.CareersBrand .sidebar-offcanvas {
    min-height: 235px;
}

.CareersBrand .navbar-nav > li > a {
    border-right: 1px solid #fff;
}

.csHomeTable {
    border: 1px solid #ccc;
}

.csHomeTable li.header {
    background-color: #eee;
}

@media (min-width: 768px) {
    #divBagTotalLink span#bagQuantity {
        background: none;
    }
}

@media (max-width: 767px) {
    .FooterSubGroup .FooterHeader {
        color: #fff;
    }

    #divBagTotalLink a#aBagLink {
        min-width: 40px;
    }
}

.FooterCardIcons.gbp,
.FooterCheck .ThreeHelpTips .CardPayments .CheckFootImg.gbp,
.BasketCardsIcons .PayImage.gbp {
    background-image: url("/images/payment/cardpayments-gbp.png");
}

.DeliveryOptions .DeliveryOptionsItem.active .InnerMethods {
    background-color: #888;
}

.CheckoutProgress .CheckoutProgressSteps > li.Active,
.DeliveryOptions .DeliveryOptionsItem .SpendVoucher {
    background-color: #333;
}

.CheckoutProgress .CheckoutProgressSteps > li.Active::after {
    border-left: 7px solid #333;
}

.DeliveryOptionsItem.active .fullwidthlabel {
    border: 1px solid #333;
}

.CheckoutHeader .CheckoutProgressBack .backCheck {
    background-position: -94px -86px;
}

@media (min-width: 768px) {
    .crusDetails .pdpDeliveryOptions {
        width: 40%;
    }
}

.LogoCheck img {
    max-height: 30px;
}

.formCompleteCTA.inactiveSubmit button:hover {
    background-color: #333 !important;
    color: #fff !important;
}

.formCompleteCTA button {
    letter-spacing: 0.1em;
}
