:root {
    /* Search autocomplete spacer */
    --autocomplete-top: 4px;
    /* Primary colours */
    --site-white: #fff;
    --site-black: #000;
    --site-dark-grey: #5e5e5e;
    --site-mid-grey: #c4c4c4;
    --site-light-grey: #e7eaec;
    --site-lightest-grey: #f8f8f8;
    --site-outlet: #a63b00;
    /* Secondary colours */
    --site-accent: var(--site-black);
    --site-accent-secondary: #b82b76;
    --site-green: #86ffa1;
    --site-dark-green: #73ca87;
    --site-red: #ed0000;
    --padding-unit: 4px;
    --header-font-size: 18px;
    --body-font-size: 14px;
    --subtitle-font-size: 16px;
    --font-weight-regular: normal;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;
    --large-container-max-width: 1600px;
    --default-container-max-width: 1400px;
    --spritesheet: url("/images/core/18mo-icons-1.svg");
    --default-font-family: RobotoCondensed, arial, helvetica, sans-serif;
    --font-family-bold: var(--default-font-family);
    --brand-font: var(--default-font-family);
    --default-letter-spacing: 0;
    --site-footer-border: var(--site-accent);
    --site-count-bubble: var(--site-white);
    --site-count-bubble-text-color: var(--site-black);
    --font-line-height: 18px;
    --border-radius: 0px;
    --sitewide-banner-border: 1px solid #e4e8eb;
    /* Icon Styling */
    --site-icon-stroke: var(--site-black);
    --site-icon-hover-stroke: var(--site-black);
    --site-icon-hover-fill: transparent;
    /* CTA styling */
    --site-cta-background: var(--site-black);
    --site-cta-text: var(--site-white);
    --site-cta-background-hover: var(--site-white);
    --site-cta-text-hover: var(--site-black);
    --site-cta-padding: 20px 15px;
    --site-cta-border: 1px solid var(--site-black);
    --site-cta-border-radius: 0px;
    --site-cta-transactional-background: var(--site-cta-background);
    --site-cta-transactional-text: var(--site-cta-text);
    --site-cta-transactional-background-hover: var(--site-cta-background-hover);
    --site-cta-transactional-text-hover: var(--site-cta-text-hover);
    --site-cta-disabled-background: var(--site-mid-grey);
    --site-cta-disabled-text: var(--site-dark-grey);
    /* Elevated Cart Variables */
    --cart-font-family: var(--default-font-family);
    --cart-font-family-bold: var(--default-font-family);
    --cart-font-family-italic: var(--default-font-family);
    --cart-sticky-element-default-top-value: 139px;
    --cart-details-margin-top-value: 32px;
    --cart-heading-font-style: normal;
    --cart-heading-itemcount-text-transform: none;
    --cart-button-background-color: var(--site-black);
    --cart-button-hover-color: var(--site-dark-grey);
    --cart-button-font-style: normal;
    --cart-button-font-weight: var(--font-weight-regular);
    --cart-button-border: 1px solid var(--site-black);
    --cart-border-radius: 0;
    --cart-button-transactional-background-color: var(--site-black);
    --cart-button-transactional-hover-background-color: var(--site-dark-grey);
    --cart-button-transactional-font-color: var(--site-white);
    --cart-total-font-weight: var(--font-weight-bold);
    --cart-error-font-color: var(--site-red);
    --cart-discount-font-color: var(--site-red);
    --cart-error-background-color: hsl(355.13deg 93.02% 98%);
    --product-line-item-line-age-restriction-color: var(--site-red);
    --product-line-item-price-font-weight: normal;
    --product-line-item-line-error-color: var(--site-red);
    --product-line-item-line-personalisation-color: var(--site-accent);
    --product-line-item-line-pre-order-color: var(--site-accent);
    --product-line-item-font-family-bold: var(--default-font-family);
    --product-line-item-discount-brand-color: var(--site-black);
    --product-line-item-discount-text-color: var(--site-red);
    --product-line-item-border-radius: 4px;
    /* Wishlist Variables */
    --wishlist-summary-header-font-style: normal;
    --wishlist-header-font-style: normal;
    --wishlist-tick-background-color: #6bab7a;
    /* Checkout Variables */
    --checkout-header-top-border: none;
    --checkout-my-bag-colour: var(--site-white);
    --checkout-active-section-colour: var(--site-accent);
    --checkout-section-text-transform: capitalize;
    --checkout-cta-border-color: var(--site-black);
    /* Focus Variables */
    --focus-outline: 1px dashed var(--site-black);
    --focus-outline-offset: 5px;
    /* Override Variables */
    --swiper-preloader-color: var(--site-black);
    --site-membership-badge-background-colour: var(--site-black);
    --site-plp-sash-height: 19px;
    --personalisation-modal-cta-background-color: var(--site-cta-background);
    --personalisation-modal-cta-color: var(--site-cta-text);
    --personalisation-modal-cta-border-color: transparent;
    --personalisation-modal-cta-hover-background-color: var(--site-cta-background-hover);
    --personalisation-modal-cta-hover-color: var(--site-cta-text-hover);
    --personalisation-modal-cta-hover-border-color: transparent;
    --personalisation-modal-cta-font-weight: var(--font-weight-bold);
    --personalisation-modal-label-font-weight: var(--font-weight-regular);
    --personalisation-loader-color: var(--site-black);
    --sitewide-banner-height: 50px;
    --sitewide-banner-border: 1px solid #e4e8eb;
    --sitewide-close-svg: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.18086 1L13.1489 13M1.18086 13L13.1489 1L1.18086 13Z' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    --sitewide-plus-svg: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 7H1.5M7.5 1V7V1ZM7.5 7V13V7ZM7.5 7H13.5H7.5Z' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    --sitewide-minus-svg: url("data:image/svg+xml,%3Csvg width='15' height='2' viewBox='0 0 15 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5 1H7.5H1.5' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    --sitewide-search-svg: url("data:image/svg+xml,%3Csvg width='30' height='30' fill='none' viewBox='-6 -6 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='6' stroke='%23000' stroke-width='2'/%3E%3Cpath d='M16.646 17.354a1 1 0 0 0 1.415-1.415l-1.415 1.415Zm-6-6 6 6 1.415-1.415-6-6-1.415 1.415Z' fill='%23000'/%3E%3C/svg%3E");
    --sitewide-chevron-svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3E%3Cpath d='M5.00103 12.0139C4.98656 11.4401 5.18159 10.8837 5.54278 10.4631L14.9339 0.576893C15.6256 -0.181049 16.7561 -0.192595 17.4623 0.549888C18.1685 1.29237 18.1794 2.50588 17.4877 3.26382C17.4787 3.27352 17.4697 3.28322 17.4625 3.29098L9.15496 12.0141L17.4632 20.7378C18.1549 21.4958 18.1423 22.7093 17.438 23.4517C16.7427 24.1825 15.6302 24.1825 14.9348 23.4516L5.54291 13.5647C5.18168 13.1441 4.9866 12.5877 5.00103 12.0139Z'/%3e%3c/svg%3E");
    --pdp-two-image-carousel-thumbnail-border-colour: transparent;
    --pdp-two-image-carousel-thumbnail-active-border-colour: var(--site-black);
    --pdp-two-image-carousel-thumbnail-border-radius: 0px;
    --pdp-two-image-carousel-icon-colour: var(--site-black);
    --pdp-two-image-carousel-icon-background-colour: var(--site-white);
    --product-filters-toggle-hover-border-color: var(--site-black);
    --product-filters-toggle-active-border-color: var(--site-black);
    --product-filters-toggle-filter-count-color: var(--site-black);
    --esd-loader-color: var(--site-black);
}
