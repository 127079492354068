.AltProdDet #divMultiplePurchases {
    display: flex;
    float: none;
    margin: 0;
    padding: 0;
    margin-bottom: 24px;
    height: 48px;

    .qtyBorder {
        border: 1px solid var(--site-mid-grey);
        background-color: var(--site-white);
        display: flex;
        max-width: 180px;
        margin-right: 4px;
        border-radius: 4px;
        align-items: center;
    }

    .s-basket-minus-button,
    .s-basket-plus-button {
        display: flex;
        transition: background-color linear 0.3s;
        height: 100%;
        align-items: center;
        text-align: center;
        margin: 0;
        flex: 1;

        & > span {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;
        }

        &:hover &:focus {
            background-color: var(--site-black);
            color: var(--site-white);

            svg path {
                stroke: var(--site-white);
            }
        }
    }
}
